import { useDelete } from "../../../hooks/delete";
import ProjectFull from "./project";
import Spinner from "../../utils/Spinner";
import { useUpdate } from "../../../hooks/update";
import { EditModal } from "./editModal";
import { useEdit } from "../../../hooks/edit";
export default function Experience({ obj, index, set, fields }) {
  const { handleUpdate, loading } = useDelete("Experience");

  const { isModalOpen, openModal, closeModal, handleEdit, loading: load } = useEdit(set);

  const handleClick = () => {
    handleUpdate(index);
    set((prev) => prev.filter((_, i) => i !== index));
  };


  if (loading || load) {
    return <Spinner />;
  }

  return (
    <div
      style={{ width: "88vw" }}
      className=" flex flex-col h-fit pb-2  bg-white rounded-lg shadow-md overflow-hidden"
    >
      <div className="flex  h-72 overflow-hidden items-center border-4 ">
        <div className="h-fit w-1/4 border-r-4 overflow-hidden">
          <img
            src={obj.logo}
            alt={obj.companyName}
            className="w-full h-full object-cover"
          />
        </div>
        <div className="flex flex-col w-1/2 pl-4 h-full gap-1 items-start ">
          <p className=" text-xl text-gray-800 ">
            <span className="font-bold">Title:&nbsp; </span>
            {obj.companyName}
          </p>
          <p className="font-bold text-md">
            Role:
            <span className=" font-normal">&nbsp; {obj.role}</span>
          </p>
          <div className="w-full overflow-y-scroll pr-2">
            <p className="text-justify text-md">
              <span className="font-bold">Description:</span>&nbsp;{" "}
              {obj.description}
            </p>
          </div>
        </div>
        <div className="w-1/4 border-l-4 h-full flex flex-col">
          <div className="flex flex-col border-b-2 h-1/4 items-center">
            <div className=" w-full flex flex-col overflow-hidden">
              <img
                src={obj.pattern}
                alt={obj.pattern}
                className="w-full h-full"
              />
            </div>
            <p className="font-bold">Pattern</p>
          </div>
          <div className="flex flex-col h-1/4  items-center">
            <div
              className="h-1/2 w-full "
              style={{ backgroundColor: obj.color }}
            ></div>
            <p>
              <span className="font-bold">Color</span>
            </p>
          </div>
          <div className="flex flex-col border-b-2 h-1/4 items-center">
            <div className="h-4/5 w-full flex flex-col overflow-hidden">
              <img
                src={obj.mobilePattern}
                alt={obj.mobilePattern}
                className="w-full h-full"
              />
            </div>
            <p className="font-bold">Mobile Pattern</p>
          </div>
          <div className="flex flex-col h-1/4 items-center">
            <div
              className="h-1/2 w-full "
              style={{ backgroundColor: obj.textColor }}
            ></div>
            <p>
              <span className="font-bold">Text Color</span>
            </p>
          </div>
        </div>
      </div>
      <div>
        <ProjectFull obj={obj} set={set} />
      </div>
      <div className="justify-center flex gap-x-8 border-t-4 mt-2">
        <button
          type="button"
          onClick={handleClick}
          className=" mt-3 focus:outline-none text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-40 py-3  dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
        >
          Delete
        </button>
        <button
          type="button"
          onClick={openModal}
          className=" mt-3 focus:outline-none text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-40 py-3  dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-900"
        >
          Edit
        </button>
      </div>
      <EditModal
        obj={obj}
        isOpen={isModalOpen}
        onClose={closeModal}
        onUpdate={(newData) => {
          const object = { ...newData, projects: obj.projects || []};
          handleEdit(object, index);
        }}
        fields={fields}
      />
    </div>
  );
}