import React from "react";
import Partners from "./Partners";
import Work from "./Work";
import ContactBox from "./ContactBox";
import Skills from "./Skills";
import Solutions from "./Solutions";
import Gallary from "./Gallary";
import Count from "./Count";

const Workspace = ({isScrollbarVisible}) => {
  return (
    <div className={`sm:flex hidden h-[38.12vw] aspect-[2.3315]  mx-[6.5vw]  ${isScrollbarVisible?"lg:mx-[5.57vw]":"lg:mx-[6.3vw]"} mt-[3.33vw] gap-[0.97vw] `}>
    <div className="w-[53.9vw] flex flex-col h-full gap-[0.97vw]">
      <div className="h-[25.48vw] w-full flex gap-[0.97vw]">
        <div className="w-[39.72vw] h-full flex flex-col gap-[0.97vw]">
          <div className="w-full h-[7.77vw]  ">
          <Count />
          </div>
          <div className="w-full h-[16.66vw] ">
            <Partners />
          </div>
        </div>
        <div className="w-[13.89vw] h-full ">
          <Work />
        </div>
      </div>
      <div className="flex h-[11.67vw] w-full gap-[0.97vw]">
        <div className="w-[23vw] h-full ">
          <ContactBox />
        </div>
        <div className="w-[30.55vw] h-full ">
          <Skills />{" "}
        </div>
      </div>
    </div>
    <div className="w-[32.7vw] h-full flex flex-col gap-[0.97vw]">
      <div className="w-full h-[16.73vw] ">
        <Solutions />
      </div>
      <div className="w-full h-[20.41vw] ">
        <Gallary />
      </div>
    </div>
  </div>
  );
};

export default Workspace;
