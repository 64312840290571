import card1 from "../../../assets/images/card1.svg"
import card2 from "../../../assets/images/card2.svg"
import card3 from "../../../assets/images/card3.svg"


export const data=[
    {
        title1:"UI/UX Case Study",
        title2:"Productivity Tracker App",
        title3:"Concept Project",
        title4:"",
        button:"view case study",
        textColor:"#FFFFFFCC",
        img:"https://res.cloudinary.com/dbrhoa186/image/upload/v1720990116/card1_mruskd.svg",
        btnHover:"after:bg-[#8F66FF]",
        link:"https://bootcamp.uxdesign.cc/productivity-tracker-app-small-ui-ux-case-study-982d192bdb85"
    },
    // {
    //     title1:"UX/UI Case Study",
    //     title2:"Pasteline - Add a Note Quickly",
    //     title3:"Client Project",
    //     title4:"IOS APP · MAC OS APP · WEB",
    //     button:"view case study",
    //     textColor:"#FFE039",
    //     img:"https://res.cloudinary.com/dbrhoa186/image/upload/v1721153363/Card_2__Pasteline_cqip0o.svg",
    //     btnHover:"after:bg-[#FFE039]",
    //     link:"https://bootcamp.uxdesign.cc/pasteline-add-a-note-quickly-09929687473e"

    // },
    
    {
        title1:"Design System",
        title2:"Intripid (Formerly Travana)",
        title3:"Coming Soon, stay tuned",
        title4:"",
        button:"view case study",
        textColor:"#FFFFFFCC",

        img:"https://res.cloudinary.com/dbrhoa186/image/upload/v1720990114/card2_cbvc6b.svg",
        link:""
    },
    // {
    //     title1:"UI/UX Case Study",
    //     title2:"Preventing Wrong UPI Transactions",
    //     title3:"Concept Project",
    //     title4:"",
    //     button:"view case study",
    //     textColor:"#06448D",

    //     img:"https://res.cloudinary.com/dbrhoa186/image/upload/v1721153845/Card_4__Preventing_Wrong_UPI_Transactions_1_xvvgjo.svg",
    //     btnHover:"after:bg-[#06448D]",
    //     link:"https://bootcamp.uxdesign.cc/preventing-wrong-upi-transactions-ux-ui-case-study-02fa658337f3"

    // },
    // {
    //     title1:"UX Case Study",
    //     title2:"MyFitnessPal",
    //     title3:"Concept Project",
    //     title4:"",
    //     button:"view case study",
    //     textColor:"#06448D",
    //     img:"https://res.cloudinary.com/dbrhoa186/image/upload/v1722411925/Card_5__MyFitnessPal_yljnbb.svg",
    //     btnHover:"after:bg-[#0051CD]",
    //     link:"https://medium.com/design-bootcamp/myfitnesspal-a-ux-case-study-a6b59432e928"

    // },
]