import { useLocation } from "react-router-dom";
import Editorial from "./editorial";
import { useUpdate } from "../../../hooks/update";
import { UpdateModal } from "./updateModel";
import Spinner from "../../utils/Spinner";
 
 export default function EditorialFull(){
    const location =useLocation();
    const details = location.state.field;

    const { data: editorial, isModalOpen, openModal, closeModal, handleUpdate, loading, setData} = useUpdate(
        details,"Editorial"
      );
    
      const updateFields = [
        { name: 'image', label: 'Image URL' },
        { name: 'title', label: 'Title' },
        { name: 'content', label: 'Content' },
        { name: 'link', label: 'Link' }

      ];

      if(loading){
        return (<Spinner/>);
      }

    return <div className="flex flex-col items-center">
        <h1 className="font-medium text-white text-4xl my-4">Editorial</h1>
        <button onClick={openModal} type="button" className="truncate w-2/3 mx-4 mt-4 py-4 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5  me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">UPDATE</button>
        <div className="flex gap-4 my-4 mx-24 flex-wrap">
        
        {editorial.map((data,index)=>(
            <Editorial key={index} id={data._id} image={data.image} title={data.title} content={data.content} index={index} set={setData}/>
        ))}
    </div>

    <UpdateModal 
        isOpen={isModalOpen} 
        onClose={closeModal} 
        onUpdate={(newData) => {
          handleUpdate(newData);
        }}
        fields={updateFields}
      />
    </div>
 }