import React from "react";
import star from "../../../assets/images/star.svg";
import { workData } from "./data/WorkData";

const Work = () => {
  return (
    <div className="bg-[#005E42] rounded-[12px] sm:rounded-[20px] border-[#FFFFFF80] border-[1px] h-full w-full">
      <div className="sm:py-[0.8vw] py-[2.95vw] border-b-[1px] border-[#FFFFFF0F]">
        <div className="flex justify-center items-center gap-x-2 font-inter font-medium text-[#CCCCCC] text-[3vw] sm:text-[0.97vw]">
          <img src={star} alt="" className="h-[4vw] sm:h-[1.25vw]  aspect-square" />
          <h1>Work Process</h1>
        </div>
        <h1 className="font-inter font-semibold text-center text-[#E6E6E6] sm:text-[1.11vw] text-[3.58vw]">
          Workflow Highlights
        </h1>
      </div>
      <div className="sm:px-[0.83vw] px-[3vw] sm:py-[0.83] ">
        {workData.map((e) => (
          <div key={workData.indexOf(e)} className="bg-[#3D8D75] rounded-[2vw] sm:rounded-[0.83vw] flex  items-center gap-x-2 px-[1.5vw] py-[0.6vw] sm:p-[0.48vw] border-[1px] border-[#FFFFFF80] mb-[2vw] sm:mb-[0.55vw]">
            <img src={e.img} alt="" className="w-[8.97vw] sm:w-[2.43vw] aspect-square" />
            <h1 className="font-inter font-medium text-[3vw] sm:text-[0.97vw] text-[#FFFFFF]">{e.text}</h1>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
