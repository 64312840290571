import React from "react";
import skills from "../../../assets/images/skills.svg";
import Marquee from "react-fast-marquee";
import { skillData } from "./data/SkillData";

const Skills = () => {
  return (
    <div className="bg-[#EB5A35] rounded-[12px] sm:rounded-[20px] border-[1px] border-[#FFFFFF80]  relative overflow-hidden w-full h-[31vw] sm:h-full sm:pt-[1.38vw] sm:pb-[1.38vw] py-[2.56vw]">
      <div className=" border-b-[1px] border-[#FFFFFF0F]">
        <div className="flex justify-center items-center gap-x-2 font-inter font-medium text-[#FFFFFF80] text-[3vw] sm:text-[0.97vw]">
          <img
            src={skills}
            alt=""
            className="w-[3.59vw] sm:w-[1.11vw]  aspect-square"
          />
          <h1>Skills</h1>
        </div>
        <h1 className="font-inter font-semibold text-center  text-[#000000] sm:text-[1.11vw] text-[3.58vw]">
          Solutions Suite
        </h1>
      </div>
      <div className="mt-[4.1vw] sm:mt-[2.15vw]">
        <Marquee autoFill speed={40}>
          {skillData?.map((e, index) => (
            <div
              key={index}
              className="bg-[#191919] rounded-[2vw] sm:rounded-[0.83vw] flex gap-x-2 h-[10.25vw] sm:h-[3.33vw] aspect-auto items-center border-[1px] border-[#FFFFFF80]  p-[1.53vw] sm:p-[0.49vw] mr-[1.67vw] sm:mr-[0.45vw]"
            >
              <img
                src={e.img}
                alt=""
                className="w-[7.17vw] sm:w-[2.43vw] aspect-square"
              />
              <h1 className="font-manrope text-[#FFFFFF] font-medium  text-[3vw] sm:text-[0.97vw]">
                {e.title}
              </h1>
            </div>
          ))}
        </Marquee>
      </div>
      <div className="bg-[#EB5A35] bottom-0 z-50 -left-4 blur-md h-3/4 w-16 absolute"></div>
      <div className="bg-[#EB5A35] bottom-0 z-50 -right-4 blur-md h-3/4 w-16 absolute"></div>
    </div>
  );
};

export default Skills;
