// import flag from "../../../../assets/images/flag1.svg"
// import smile from "../../../../assets/images/smile.svg"
// import star1 from "../../../../assets/images/star1.svg"

export const CountData=[
    {
        number:45,
        type:"Projects",
        logo:"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280399/images/flag1_pkeuy6.svg"
    },
    {
        number:30,
        type:"Happy Clients",
        logo:"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280431/images/smile_cfbnfs.svg"
    },
    {
        number:10,
        type:"Certifications",
        logo:"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280431/images/smile_cfbnfs.svg"
    },
    {
        number:4,
        type:"Year Expertise",
        logo:"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280433/images/star1_kjzoaa.svg"
    },
]
