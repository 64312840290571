import { useState } from "react";

export function useDelete(field) {
  const [loading, setloading] =  useState(false);

  const handleUpdate = async (newData) => {

    try {
      setloading(true);
      await fetch(`https://sg-portfolio-2-0-server.onrender.com/user/delete${field}/${newData}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user")}`,
        },
      });
    } catch (error) {
      alert("Internal server error: ", error.message);
    }
    finally{
      setloading(false);
    }
  };

  return {
    handleUpdate,
    loading
  };
}