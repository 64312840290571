import React from "react";
import Card from "./Card";
import "./Card.css";
import { data } from "./CardData";

const ProjectSectionPhone = () => {
  return (
    <div className="block sm:hidden mx-[5.56vw] mt-[27.69vw]">
      <div className="module flex-col sm:flex-row justify-between">
        <div className="sm:module__content sm:w-1/2">
          <div className=" sm:module__rich-text text-center sm:text-start">
            <h1 className="font-inter text-[10.25vw] sm:text-[3.61vw] font-semibold text-[#FFFFFF]">
              projects
            </h1>
            <p className="font-inter font-medium text-[#FFFFFF80] text-[3vw] sm:text-[1.38vw] mt-[2vw] sm:mt-[1.11vw] leading-[4.6vw] sm:leading-[1.94vw]">
              Helping brands achieve sustainable results, <br /> Whether it's a
              vibrant website or a minimal mobile <br /> app or branding you
              seek. I deliver exceptional <br />
              quality for all.
            </p>
          </div>
        </div>
        <div className="module__cards sm:w-1/2  sm:mt-0 mt-20">
          <Card
            bgColor={"bg-transparent sm:block hidden top-[10%] -translate-y-1/2 "}
            rotate={0}
            index={-1}
          />
          {data?.map((e, index) => (
            <Card
              bgColor={"top-[10%] -translate-y-1/2"}
              rotate={index % 2 === 0 ? 5 : -5}
              img={e.img}
              title1={e.title1}
              title2={e.title2}
              title3={e.title3}
              title4={e.title4}
              textColor={e.textColor}
              button={e.button}
              link={e.link}
              btn={e.btnHover}
              index={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectSectionPhone;
