import React from "react";
import rocket from "../../../assets/images/rocket.svg";
import PartnerMarq from "./PartnerMarq";
import "./Workspace.css"

const Partners = () => {
  return (
    <div className="bg-[#FFFFFF] w-full rounded-[12px] sm:rounded-[20px] sm:pt-[1.38vw] sm:pb-[2vw] py-[2.56vw] relative overflow-hidden h-[44.35vw] sm:h-full partner">
      <div className="font-inter font-medium text-[3vw] sm:text-[0.97vw] text-[#999999] flex items-center justify-center gap-x-2 ">
        <img src={rocket} alt="" className="w-[3.59vw] sm:w-[1.11vw] aspect-square"/>
        <h1>My Clients</h1>
      </div>
      <h1 className="font-inter font-semibold sm:text-[1.11vw] text-[3.58vw] text-[#333333] text-center">
        Satisfied Partners
      </h1>
      <div className="flex flex-col gap-y-[2vw] sm:gap-y-[0.7vw] mt-[4.1vw] sm:mt-[2.15vw]">
        <PartnerMarq directionMarq={"left"} />
        <PartnerMarq directionMarq={"right"} />
      </div>
      <div className="absolute bg-white bottom-4 z-50 -left-4 blur-md h-3/4 w-16"></div>
      <div className="absolute bg-white bottom-4 z-50 -right-4 blur-md h-3/4 w-16"></div>
    </div>
  );
};

export default Partners;
