import React, { useRef } from "react";
//import sarthak from "../../../assets/images/sarthak.svg";
import LogoCylinder from "./LogoCylinder1";
// import sticker1 from "../../../assets/images/stk1.svg";
// import sticker2 from "../../../assets/images/stk2.svg";
// import sticker3 from "../../../assets/images/stk3.svg";
// import sticker4 from "../../../assets/images/stk4.svg";
// import sticker5 from "../../../assets/images/stk5.svg";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";

const Hero = () => {
  const dragRef = useRef();
  const [divWidth, setDivWidth] = useState(0);
  const [divWidth1, setDivWidth1] = useState(0);
  const divRef = useRef(null);

  const updateWidth = () => {
    if (divRef.current) {
      setDivWidth(divRef.current.offsetWidth);
    }
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  useEffect(() => {
    if (divWidth) {
      setDivWidth1(divWidth);
    }
  }, [divWidth]);

  return (
    // <div className=" bg-[#f7f8f9] rounded-[40px] mx-10 min-h-[630px] xs:min-h-[630px] xmd:min-h-[950px]  md1:min-h-[1100px] md:min-h-[800px] lg:min-h-[900px]">
    // 2xl:mx-[5.89vw] 2xxl:mx-[5.99vw]
    <div
      className=" min-h-[153.84vw] sm:min-h-[50vw]  rounded-[20px] md1:rounded-[32px] bg-[#f7f8f9] relative overflow-hidden mx-[4.1vw] lg:mx-[5.79vw]  pt-[7.69vw] sm:pt-[4.17vw] mt-[80px] sm:mt-[11vw] sm1:mt-[9.3vw] md1:mt-[9vw] lg:mt-[7.3vw] 2xl:mt-[6.8vw] 4xl:mt-[6vw]"
      ref={dragRef}
    >
      <motion.h1
        initial={{ y: -1000 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.3 }}
        viewport={{ once: true }}
        className="text-[#4B4B4B] text-[3.59vw] sm:text-[1.67vw] font-inter font-normal text-center"
      >
        my name is <span className="text-black font-medium">SARTHAK GOYAL</span>{" "}
        <span className=" italic">aka</span>{" "}
        <span className="text-black font-medium">UIX Chef</span>
      </motion.h1>

      <motion.div
        initial={{ y: -1000 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.3 }}
        viewport={{ once: true }}
        className="flex w-full  flex-col sm:flex-row relative justify-center items-center mt-[2.1vw] sm:mt-[-2.668vw] z-[50]"
      >
        <div className="flex justify-between sm:justify-center items-center">
          <h1 className="text-black font-impact mr-[7vw] sm:mr-0 text-[21.53vw] sm:text-[11.1vw]">
            I&apos;m
          </h1>
          <p className=" font-inter font-normal text-black text-[4.1vw] sm:text-[1.53vw] text-center leading-[4.96vw] sm:leading-[1.8vw]">
            a UX/UI expert, <br />
            Advisor, Mentor,
            <br /> Design Leader, Brand
            <br /> Builder, Product Guy
            <br /> and a
          </p>
        </div>
        <h1 className="text-[#E76262] mt-[-10vw] sm:mt-0 text-[21.53vw] sm:text-[11.1vw] font-impact">
          designer
        </h1>
      </motion.div>

      <div
        ref={divRef}
        className="absolute left-1/2 bottom-0 -translate-x-1/2 z-[1000] mx-auto aspect-[0.98] h-[77.94vw] sm:h-[40.1vw] sm:mt-[-11.2vw] my-custom-div"
      >
        <motion.img
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.3, delay: 0.4 }}
          src={"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280418/images/sarthak_gog4r6.svg"}
          className="aspect-[0.98] h-[77.94vw] sm:h-[40.1vw] object-cover mx-auto z-[1000] pointer-events-none"
          alt="sarthak"
        />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3, delay: 0.8 }}
          viewport={{ once: true }}
          className="absolute w-fit bottom-[-90px] xxs:bottom-[-85px] xs:-bottom-[70px] sm:-bottom-[85px] sm1:bottom-[-70px] lg:bottom-[-60px] xl:bottom-[-40px] 3xl:bottom-[-30px] left-[53%] lg:left-1/2 -translate-x-1/2 z-[500]"
        >
          <LogoCylinder divWidth={divWidth1} />
        </motion.div>
      </div>

      {/* <motion.img // green left
        drag
        dragConstraints={dragRef} // Make sure dragRef is correctly defined
        dragSnapToOrigin={true}
        whileTap={{ cursor: "grabbing" }}
        dragTransition={{ bounceStiffness: 200, bounceDamping: 20 }}
        src={"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280439/images/stk1_gnjew9.svg"}
        alt="sticker1"
        initial={{ x: -1000, rotate: -45 }}
        animate={{ x: 0 }}
        transition={{ duration: 0.3 }}
        viewport={{ once: true }}
        className="w-[16.9vw] sm:w-[8.75vw] aspect-square -rotate-45 absolute left-[4.5vw]  sm:left-[8vw] top-[70vw] sm:top-[25.77vw] hover:cursor-grab"
      />

      <motion.img // yellow left bottom
        drag
        dragConstraints={dragRef}
        dragSnapToOrigin={true}
        whileTap={{ cursor: "grabbing" }}
        dragTransition={{ bounceStiffness: 200, bounceDamping: 20 }}
        src={"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280440/images/stk2_uhoqce.svg"}
        alt="sticker1"
        initial={{ x: -1000, rotate: -30 }}
        animate={{ x: 0 }}
        transition={{ duration: 0.3 }}
        className=" w-[30.7vw] sm:w-[20.8vw] aspect-square rotate-[-30deg] absolute sm:left-[-2vw] left-[-14vw] top-[100vw] sm:top-[42vw] hover:cursor-grab"
      />
      <motion.img // blue right
        drag
        dragConstraints={dragRef}
        dragSnapToOrigin={true}
        whileTap={{ cursor: "grabbing" }}
        dragTransition={{ bounceStiffness: 200, bounceDamping: 20 }}
        src={"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280441/images/stk3_ocyk4l.svg"}
        alt="sticker1"
        initial={{ x: 1000, rotate: -50 }}
        animate={{ x: 0 }}
        transition={{ duration: 0.3 }}
        className="w-[16.9vw] sm:w-[8.75vw] aspect-square rotate-[-50deg] absolute right-[5vw] top-[66vw] sm:top-[22.77vw] hover:cursor-grab"
      />
      <motion.img // green right
        drag
        dragConstraints={dragRef}
        dragSnapToOrigin={true}
        whileTap={{ cursor: "grabbing" }}
        dragTransition={{ bounceStiffness: 200, bounceDamping: 20 }}
        src={"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280442/images/stk4_avgnee.svg"}
        alt="sticker1"
        initial={{ x: 1000, rotate: -50 }}
        animate={{ x: 0 }}
        transition={{ duration: 0.3 }}
        className="w-[16.9vw] sm:w-[8.75vw] aspect-square rotate-[-50deg] absolute sm:right-[-3vw] right-[-7vw] top-[80vw] sm:top-[29.77vw] hover:cursor-grab"
      />

      <motion.img // orange right
        drag
        dragConstraints={dragRef}
        dragSnapToOrigin={true}
        whileTap={{ cursor: "grabbing" }}
        dragTransition={{ bounceStiffness: 200, bounceDamping: 20 }}
        src={"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280443/images/stk5_alddmo.svg"}
        alt="sticker1"
        initial={{ x: 1000, rotate: 40 }}
        animate={{ x: 0 }}
        transition={{ duration: 0.3 }}
        className="w-[49vw]  sm:w-[27.77vw] aspect-auto rotate-[40deg] absolute      right-[-25vw] sm:right-[-16vw] top-[110vw] sm:top-[45vw] hover:cursor-grab"
      /> */}
    </div>
    // </div>
  );
};

export default Hero;
