import React, { useState, useEffect } from "react";
import "./star.css";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.svg";


export default function Preloader({ isLoading,progress }) {
  // const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  
  // useEffect(() => {
  //   const startTime = Date.now();
  //   const interval = setInterval(() => {
  //     const elapsedTime = Date.now() - startTime;
  //     const newProgress = Math.min(Math.floor((elapsedTime / 4000) * 100), 100);
  //     setProgress(newProgress);

  //     if (elapsedTime >= 4000 && !isLoading) {
  //       clearInterval(interval);
  //     }
  //   }, 40);

  //   return () => clearInterval(interval);
  // }, []);

  

  return (
    <div className="h-screen flex flex-col items-center justify-center gap-y-3">
      <img src={logo} alt="" className="sm1:w-[17vw] w-[50vw] aspect-auto"/>
      {isLoading ? (
        <div className="progress-bar-container">
          <div className=""></div>
          <div className="progress-bar-fill" style={{ width: `${progress}%` }}>
            <div className="star"></div>
          </div>
        </div>
      ) : (
        navigate("/")
      )}
    </div>
  );
}
