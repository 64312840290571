import { useEffect, useState } from "react";

export function useUpdate(initialData, field) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState(initialData);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [loading, setloading] = useState(false);

  const handleUpdate = async (newData) => {
    try {
      closeModal();
      setData((prev) => [...prev, newData]);
      setloading(true);
      const response = await fetch(
        `https://sg-portfolio-2-0-server.onrender.com/user/update${field}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("user")}`,
          },
          body: JSON.stringify(newData),
        }
      );
      if (!response.ok) {
        throw new Error("Failed Updation");
      }
    } catch (error) {
      alert("Internal server error: ", error.message);
    } finally {
      setloading(false);
    }
  };
  const handleEdit = async (newData,index) => {
    try {
      closeModal();
      //setData((prev) => [...prev, newData]);
      setloading(true);
      const response = await fetch(
        `https://sg-portfolio-2-0-server.onrender.com/user/editExperience/${index}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("user")}`,
          },
          body: JSON.stringify(newData),
        }
      );
      if (!response.ok) {
        throw new Error("Failed Updation");
      }
    } catch (error) {
      alert("Internal server error: ", error.message);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    try {
      setloading(true);
      fetch("https://sg-portfolio-2-0-server.onrender.com/user/details", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user")}`,
          "Content-Type": "application/json",
        },
      }).then(async (res) => {
        if (!res) {
          throw new Error("Fetching Failed");
        }
        const response = await res.json();
        setData(()=>(
          field === "PersonalImages"
            ? response.personalImages
            : response[field.toLowerCase()])
        );
      });
    } catch (error) {
      alert("Internal server error: ", error.message);
    } finally {
      setloading(false);
    }
  }, []);

  return {
    data,
    isModalOpen,
    openModal,
    closeModal,
    handleUpdate,
    setData,
    loading,
    handleEdit
  };
}