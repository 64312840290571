import React from "react";
import { Link } from "react-router-dom";
import an from "../../../assets/images/an1.png"

const ProtolabMarq = ({ inHandler, outHandler, image, title,link }) => {
  return (
    <Link to={link} target="_blank">
      <div
        // onMouseEnter={() => inHandler()}
        // onMouseLeave={() => outHandler()}
        className="border-[#FFFFFF1A] border-[1px] rounded-[12px] bg-[#111111] sm:w-[13.89vw] w-[51.28vw] aspect-[1.428] p-[3vw] sm:p-[0.83vw] items-center gap-x-3  mr-[7.16vw] sm:mr-[1.94vw]"
      >
        <img
          src={image}
          className="aspect-[1.46] mx-auto sm:w-[12.22vw] w-[45.12vw] object-cover   rounded-[8px] bg-white"
          alt=""
        />
        <h1 className="font-light text-[#FFFFFF]  truncate text-[4.1vw] sm:text-[1.11vw] font-inter mt-[3vw] sm:mt-[0.83vw] ">
          {title}{" "}
        </h1>
      </div>
    </Link>
  );
};

export default ProtolabMarq;
