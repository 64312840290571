import React from 'react';
import { useLocation } from "react-router-dom";
import Protolab from "./protolab";
import { useUpdate } from '../../../hooks/update';
import { UpdateModal } from './updateModel';
import Spinner from '../../utils/Spinner';

export default function ProtoLab() {
  const location = useLocation();
  const initialProtolab = location.state.field;
  
  const { data: protolab, isModalOpen, openModal, closeModal, handleUpdate, loading, setData} = useUpdate(
    initialProtolab,"Protolab"
  );


  const updateFields = [
    { name: 'image', label: 'Image URL' },
    { name: 'title', label: 'Title' },
    { name: 'link', label: 'Link' }
  ];
  
  if(loading){
    return (<Spinner/>);
  }

  return (
    <div className="flex flex-col items-center">
      <h1 className="font-medium text-white text-4xl my-4">Protolab</h1>
      <button 
        type="button" 
        onClick={openModal}
        className="truncate w-2/3 mx-4 mt-4 py-4 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
      >
        UPDATE
      </button>
      <div className="flex gap-4 my-4 mx-24 flex-wrap">
        {protolab.map((data, index) => (
          <Protolab key={index} id={data._id} image={data.image} title={data.title} index={index} set={setData}/>
        ))}
      </div>

      <UpdateModal 
        isOpen={isModalOpen} 
        onClose={closeModal} 
        onUpdate={(newData) => {
          handleUpdate(newData);
        }}
        fields={updateFields}
      />
    </div>
  );
}