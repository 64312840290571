import React from "react";
import { CountData } from "./data/Count";
import CountCard from "./CountCard";

const Count = () => {
  return (
    <div className="flex flex-wrap gap-[1.53vw] sm:gap-[0.97vw] w-full h-full">
      {" "}
      {CountData.map((e) => (
        <CountCard key={CountData.indexOf(e)} data={e} />
      ))}
    </div>
  );
};

export default Count;
