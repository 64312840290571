import React from "react";
//import bg from "../../../assets/images/banner3.svg";
import BannerMarq from "./BannerMarq";


const Banner = () => {

  const data1=[
    {
      name:"LinkedIn Top UX Voice",
      color:"hover:text-[#075FF4]"
    },
    {
      name:"product mindset",
      color:"hover:text-[#1C7E3E]"
    },
    {
      name:"research relisher",
      color:"hover:text-[#9B176E]"
    }
  ]
  const data2=[
    {
      name:"SPLINE SAVANT",
      color:"hover:text-[#6921B5]"
    },
    {
      name:"figma fanboi",
      color:"hover:text-[#EF5545]"
    },
    {
      name:"framer fancier",
      color:"hover:text-[#2121B4]"
    }
  ]
  
  return (
    <div className="bg-[#101010] border-[#FFFFFF33] border-[1px]  rounded-[20px] md1:rounded-[32px] mx-[4.1vw] lg:mx-[5.54vw] mt-[7.17vw] sm:mt-[3.33vw] py-[5.69vw] pb-[10vw] sm:py-[2.44vw] min-h-fit overflow-hidden">
      <div className=" flex flex-col sm:flex-row items-center gap-x-[6.11vw] mx-[6.11vw] mb-[2.44vw]">
        <img src={"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280380/images/banner3_qpbcvy.svg"} alt="loading" className="w-[75.5vw] sm:w-[21.2vw]  border-[#FFFFFF33] border-[1px] rounded-[5vw] sm:rounded-[1.38vw]  aspect-square" />
        <div className=" sm:mt-[-2.5vw]">
          <h1 className="font-marker font-normal text-[13.33vw] sm:text-[5.83vw] text-[#9DF917]">
            <span className="text-[18.46vw] sm:text-[8.33vw]">H</span>ello
            <span className="text-[18.46vw] sm:text-[8.33vw]">!</span>
          </h1>
          <p className="font-inter font-normal text-[3.58vw] sm:text-[1.39vw] leading-[5.38vw] sm:leading-[1.94vw] sm:-mt-5 text-[#FFFFFF]">
            I am Sarthak, as a Digital Product Designer with experience in
            creating a wide range of digital products, I craft research-backed,
            user-centric solutions that seamlessly blend functionality and
            aesthetics. Currently building Intripid. <br />
            <br /> You&apos;ll find references to rock music, pop culture, video
            games, blockchain, travel love, and AI in both my work and
            personality.
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-y-5  sm:gap-y-4 md:gap-y-0">
        <BannerMarq
          data={data1}
          directionMarq="right"
          onClick={()=>console.log("ok google")}
        />
        <BannerMarq
          data={data2}
          directionMarq="left"
        />
      </div>
    </div>
  );
};

export default Banner;
