import { useProUpdate } from "../../../hooks/projectUpdate";
import { UpdateModal } from "./updateModel";
import Spinner from "../../utils/Spinner";
export default function ProjectFull({ obj, set }) {
  const { isModalOpen, openModal, closeModal, handleAddUpdate, handleRemove, loading } =
    useProUpdate(obj._id);

  const updateFields = [
    { name: "title", label: "Project Link" },
    { name: "url", label: "Image URL" },
  ];

  if (loading) {
    return <Spinner></Spinner>
  }

  return (
    <div className="flex flex-col items-center gap-2">
      <p className="font-bold text-2xl">Projects</p>
      <div className="flex gap-4 flex-wrap">
        {obj.projects ? obj.projects.map((data, index) => (
          <div
            key={index}
            className=" flex flex-col border-2 w-40 overflow-hidden text-center"
          >
            <p className="px-1">{data.title}</p>
            <img className="h-32 w-full" src={data.url} alt={data.title}></img>
            <button
              type="button"
              onClick={() => {
                handleRemove(obj._id, index);
                set((p) => {
                  return p.map((d) =>
                    d._id === obj._id
                      ? {
                        ...d,
                        projects: d.projects.filter((_, i) => i !== index),
                      }
                      : d
                  );
                });
              }}
              className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mx-2 my-1 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
            >
              Delete
            </button>
          </div>
        )) : ""}

        <button
          type="button"
          onClick={openModal}
          className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-4xl px-8 py-20 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
        >
          &#43;
        </button>
      </div>
      <UpdateModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onUpdate={(newData) => {
          handleAddUpdate(newData);
          set((p) => {
            return p.map((d) =>
              d._id === obj._id
                ? { ...d, projects: [...d.projects || "", newData] }
                : d
            );
          });
        }}
        fields={updateFields}
      />
    </div>
  );
}