import React from "react";
import projects from "../../../assets/images/projects.svg";
import GallaryMarq from "./GallaryMarq";
import "./Workspace.css"
import { Link } from "react-router-dom";

const Gallary = () => {
  return (
    <div className="bg-[#FFFFFF] border-[1px] border-[#000000] border-dashed rounded-[12px] sm:rounded-[20px] sm:pt-[1.38vw] py-[2.56vw] sm:pb-[1.38vw] relative overflow-hidden h-[57.17vw] sm:h-full w-full partner">
      <div className="font-inter font-medium text-[3vw] sm:text-[0.97vw] text-[#999999] flex items-center justify-center gap-x-2 ">
        <img src={projects} alt="" className="w-[3.59vw] sm:w-[1.11vw] aspect-square" />
        <h1>Projects</h1>
      </div>
      <h1 className="font-inter font-semibold sm:text-[1.11vw] text-[3.58vw] text-[#333333] text-center">
        Works Gallery
      </h1>
      <div className="flex flex-col gap-y-[2vw] sm:gap-y-[0.7vw] sm:mt-[2vw] mt-[4vw]">
        <GallaryMarq directionMarq={"left"} />
        <GallaryMarq directionMarq={"right"} />
      </div>
      <div className="absolute bg-white bottom-4 z-50 -left-4 blur-md h-3/4 w-16"></div>
      <div className="absolute bg-white bottom-4 z-50 -right-4 blur-md h-3/4 w-16"></div>
      <Link to="https://www.behance.net/sarthakg03" target="_blank" className="absolute border-[#FFFFFF] border-[1.5px] rounded-xl bg-[#000000] text-center font-inter font-semibold  text-[3vw] sm:text-[0.97vw] text-[#FFFFFF] px-[6.15vw] sm:py-[0.9vw] py-[3.33vw] sm:px-[1.67vw] bottom-4 left-1/2 -translate-x-1/2 z-50 cursor-pointer">
            <span>VIEW WORKS</span>
      </Link>
    </div>
  );
};

export default Gallary;
