// import work1 from "../../../../assets/images/work1.svg"
// import work1 from "../../../../assets/images/goals.svg"
// import work2 from "../../../../assets/images/research.svg"
// import work3 from "../../../../assets/images/wireframe.svg"
// import work4 from "../../../../assets/images/proto.svg"
// import work5 from "../../../../assets/images/designfinal.svg"

export const workData=[
    {
        img:"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280407/images/goals_adance.svg",
        text:"Goals"
    },
    {
        img:"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280416/images/research_ml0cbc.svg",
        text:"Research"
    },
    {
        img:"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280445/images/wireframe_b95vjl.svg",
        text:"Wireframe"
    },
    {
        img:"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280415/images/proto_fwvpvk.svg",
        text:"Phototyping"
    },
    {
        img:"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280396/images/designfinal_bxahkn.svg",
        text:"Finalize Design"
    },
]