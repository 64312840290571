import React from 'react'

const SnapshotMarq = ({skew,image}) => {
  return (
    <div className={` ${skew} w-[33.5vw] ml-[-10px] flex justify-center items-center sm:w-[13.89vw] aspect-[1.5] bg-white`}>
      <img src={image} alt="" className={` w-[32.5vw] sm:w-[12.89vw] aspect-[1.5]`}/>
    </div>
  )
}

export default SnapshotMarq
