import React, { useEffect, useState } from "react";
//import logo from "../../../assets/images/logo.svg";
import { motion, AnimatePresence } from "framer-motion";
import "./Header.css";
import Hamburger from "./Hamburger";
import { Link } from "react-router-dom";

const Header = ({
  handleCase,
  userData,
  handleCaseLogo,
  isScrollbarVisible,
  status,
}) => {
  const [screenSize, setScreenSize] = useState(false);
  const [showmenu, setShowmenu] = useState(false);

  const [lastScrollY, setLastScrollY] = useState(0);
  const [showNavbar, setShowNavbar] = useState(true);

  const changedisplay = () => {
    if (window.innerWidth <= 1024) setScreenSize(true);
    else {
      setScreenSize(false);
      setShowmenu(false);
    }
  };

  window.addEventListener("resize", changedisplay);

  const hamsburgerhandler = () => {
    setShowmenu(!showmenu);
  };

  useEffect(() => {
    changedisplay();
  }, []);

  const scrollHandler = () => {
    if (window.scrollY >= 100) {
      if (window.scrollY > lastScrollY) {
        // Scroll down
        setShowNavbar(false);
      } else {
        // Scroll up
        setShowNavbar(true);
      }
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, [lastScrollY]);

  useEffect(() => {
    if (status) {
      setShowmenu(false);
    }
  }, [status]);

  const variants = {
    initial: { opacity: 0 },
    animate: (i) => ({
      opacity: 1,
      transition: { duration: 0.1, delay: i * 0.1 },
    }),
    exit: (i) => ({
      opacity: 0,
      transition: { duration: 0.1, delay: (listItems.length - 1 - i) * 0.1 },
    }),
  };

  const listItems = [
    { id: 1, label: "case studies", onClick: () => handleCase() },
    { id: 2, label: "resume", to: userData.resume, target: "_blank" },
    {
      id: 3,
      label: "behance",
      to: "https://www.behance.net/sarthakg03",
      target: "_blank",
    },
    {
      id: 4,
      label: "linkedin",
      to: "https://www.linkedin.com/in/sarthakgoyal-uixchef/",
      target: "_blank",
    },
    {
      id: 5,
      label: "threads",
      to: "https://www.threads.net/@uix.chef",
      target: "_blank",
    },
    {
      id: 6,
      label: "medium",
      to: "https://uixchef.medium.com/",
      target: "_blank",
    },
    {
      id: 7,
      label: "instagram",
      to: "https://www.instagram.com/uix.chef?igsh=MXM1MXZxZ2o2bmkxZw==",
      target: "_blank",
    },
  ];

  return (
    <motion.div
      initial={{ y: 0, borderRadius: 32 }}
      animate={{
        y: showNavbar ? 0 : -90,
        borderRadius: showmenu ? 12 : 32,
      }}
      transition={{ duration: 0.3 }}
      className=" fixed top-0 z-[2000] mt-3 mb-2"
      style={{ width: isScrollbarVisible ? "calc(100vw - 16px)" : "100vw" }}
    >
      <div
        // sm:ml-[5.5555vw] sm:mr-[6.6666vw]
        className={` w-[92vw]  mx-[3.99vw] md:w-[90vw] ${
          isScrollbarVisible ? "lg:w-[87.3vw]" : "lg:w-[88.4vw]"
        } md:mx-auto lg:mx-auto rounded-[20px] lg:rounded-[32px] text-[#FFFFFF] text-[1.11vw] font-inter font-medium bg-[#242424]  z-[2000]`}
      >
        <div className="w-full rounded-[20px] lg:rounded-[32px] flex justify-between items-center py-4 px-8 text-[#FFFFFF] text-[1.11vw] font-inter font-medium bg-[#242424] z-[2000]">
          <div className="flex items-center gap-x-4 md:gap-x-8 lg:gap-x-14">
            <div onClick={() => handleCaseLogo()} className="cursor-pointer">
              <img
                src={
                  "https://res.cloudinary.com/dbrhoa186/image/upload/v1722280409/images/logo_nkalkx.svg"
                }
                alt="Logo"
                className="pointer-events-none cursor-pointer"
              />
            </div>

            <ul className="md1:flex hidden items-center gap-x-2 md:gap-x-5 lg:gap-x-8">
              <Link onClick={() => handleCase()}>
                {" "}
                <li className="link">case studies</li>
              </Link>
              <Link to={userData.resume} target="_blank">
                {" "}
                <li className="link">resume</li>
              </Link>
            </ul>
          </div>
          <div className="md1:flex hidden items-center gap-x-8">
            <ul className="flex items-center gap-x-2 md:gap-x-5 lg:gap-x-8">
              <Link to="https://www.behance.net/sarthakg03" target="_blank">
                {" "}
                <li className="link">behance</li>
              </Link>
              <Link
                to="https://www.linkedin.com/in/sarthakgoyal-uixchef/"
                target="_blank"
              >
                {" "}
                <li className="link">linkedin</li>
              </Link>
              <Link to="https://www.threads.net/@uix.chef" target="_blank">
                {" "}
                <li className="link">threads</li>
              </Link>
              <Link to="https://uixchef.medium.com/" target="_blank">
                {" "}
                <li className="link">medium</li>
              </Link>
              <Link
                to="https://www.instagram.com/uix.chef?igsh=MXM1MXZxZ2o2bmkxZw=="
                target="_blank"
              >
                {" "}
                <li className="link">instagram</li>
              </Link>
            </ul>
            <Link to="mailto:hey@uixchef.com">
              {" "}
              <button className="button text-black font-semibold text-[0.97vw] bg-[#FFFFFF] px-[1.6vw] py-[0.83vw] rounded-xl">
                CONTACT
              </button>
            </Link>
          </div>
          <div className="md1:hidden block sm:my-auto z-[3000]">
            <div
              className={`cursor-pointer flex flex-col justify-center items-end ${
                showmenu ? "change" : ""
              } h-6 w-6 mt-21`}
              onClick={hamsburgerhandler}
            >
              <i
                className={`block ${
                  showmenu ? "w-full bg-white" : "w-3/4 bg-white"
                } h-[2px] linex line1 mb-1`}
              ></i>
              <i
                className={`block w-full linex h-[2px] bg-white line2 mb-1`}
              ></i>
              <i
                className={`block linex ${
                  showmenu ? "w-full bg-white" : "w-1/2 bg-white"
                } h-[2px] line3`}
              ></i>
            </div>
          </div>
        </div>
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: showmenu ? "fit-content" : 0 }}
          transition={{ duration: 0.4 }}
          className="md1:hidden text-[4vw]"
        >
          <AnimatePresence>
            <ul className="flex flex-col items-center gap-y-5 pt-5 pb-10">
              {showmenu &&
                listItems.map((item, i) => (
                  <Link
                    key={item.id}
                    to={item.to}
                    target={item.target}
                    onClick={item.onClick}
                  >
                    <motion.li
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      custom={i}
                      variants={variants}
                      className={`link ${item.id === 2 ? "mb-4" : null}`}
                    >
                      {item.label}
                    </motion.li>
                  </Link>
                ))}
            </ul>
          </AnimatePresence>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Header;
