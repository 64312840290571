import React from "react";
import { motion } from "framer-motion";
import "./Card.css";
import { Link } from "react-router-dom";

const Card = ({
  bgColor,
  rotate,
  img,
  title1,
  title2,
  title3,
  title4,
  textColor,
  button,
  index,
  link,
  btn,
}) => {
  return (
    <motion.div
      initial={{ rotate: 0 }}
      whileInView={{ rotate: rotate }}
      transition={{ duration: 0.4, delay: 0.2 }}
      className={`mx-auto w-[83.5vw] sm:w-[37.7vw] sticky aspect-square rounded-[12px] sm:rounded-[24px] module__card shadow-2xl shadow-current  bg-cover bg-no-repeat ${bgColor} p-8`}
      style={{ backgroundImage: `url(${img})` }}
    >
      {index === -1 ? null : index === 1 ? (
        <div
          className={`w-fit mx-auto font-inter  ${"text-white"} text-center`}
        >
          <div>
            <div className=" flex justify-center items-center gap-x-2 w-fit mx-auto font-semibold text-[2.58vw] sm:text-[0.97vw] bg-[#FFFFFF] px-[3vw] sm:px-[0.83vw] py-[1.53vw] sm:py-[0.41vw] mt-[-3vw] sm:mt-[-1vw] rounded-xl text-[#DC2626]">
              <span className="sm:w-[0.41vw] w-[1.53vw] h-[1.53vw] sm:h-[0.41vw] aspect-square rounded-full bg-[#DC2626]"></span>
              In-Progress
            </div>
            <h1
              className=" text-[3vw] sm:text-[1.52vw] font-normal mt-[2vw] sm:mt-[0.55vw]"
              style={{ color: textColor }}
            >
              {title3}
            </h1>
          </div>
          <div className="sm:mt-[4.72vw] mt-[10.4vw]">
            <h1 className="text-[3vw] sm:text-[1.38vw] font-semibold">
              {title1}
            </h1>
            <h1 className="my-0 text-[4.1vw] sm:text-[1.66vw] font-semibold">
              {title2}
            </h1>
          </div>

          {/* <h1 className="text-[#FFFFFFCC]">{title3}</h1> */}
        </div>
      ) : (
        <div
          className={`w-fit mx-auto font-inter  ${ index===2?"text-[#0051CD]":"text-white"} text-center`}
        >
          <h1 className="text-[3vw] sm:text-[1.38vw] font-semibold">
            {title1}
          </h1>
          <h1 className="my-0 text-[4.1vw] sm:text-[1.66vw] font-semibold">
            {title2}
          </h1>
          <h1 className="text-[#FFFFFFCC] font-normal text-[3vw] sm:text-[1.38vw]">
            {title4}
          </h1>
          <h1
            className=" text-[3vw] sm:text-[1.25vw] font-normal"
            style={{ color: textColor }}
          >
            {title3}
          </h1>
          {/* <h1 className="text-[#FFFFFFCC]">{title3}</h1> */}
          <Link to={link} target="_blank">
            <div
              className={`button12 ${btn} ${
                index === 2 ? "hover:text-white" : null
              } text-black w-fit mx-auto font-semibold text-[3vw] sm:text-[1.11vw] ${index===2?"bg-[#a9cbff]":"bg-[#FFFFFF]"}  px-[7vw] sm:px-[1.6vw] py-[3vw] sm:py-[0.83vw] mt-[3vw] sm:mt-[1vw] rounded-xl`}
            >
              {button}
            </div>
          </Link>
          
        </div>
      )}
    </motion.div>
  );
};

export default Card;
