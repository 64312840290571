// import React from "react";
// import logo from "../../../assets/images/logo.svg";
// import "./footer.css";
// import { Link } from "react-router-dom";

// const FooterDown = ({ handleCase }) => {
//   return (
//     <div className="flex sm:flex-row flex-col justify-between  sm:items-center gap-y-[6vw] rounded-tl-[32px] mt-10 rounded-tr-[32px] py-[6vw] sm:py-[2.78vw] sm:px-[1.67vw] px-[4vw] text-[#FFFFFF] text-[3.5vw] sm:text-[1.11vw] font-inter font-medium bg-[#121212]">
//       <div className="flex sm:flex-row flex-col items-start sm:tems-center gap-x-14 gap-y-[6vw]">
//         <img src={logo} alt="Logo" className=" pointer-events-none" />{" "}
//         <ul className="flex sm:flex-row flex-col sm:items-center gap-y-[2vw] gap-x-8">
//           <Link onClick={() => handleCase()}>
//             {" "}
//             <li className="link">case studies</li>
//           </Link>
//           <Link>
//             {" "}
//             <li className="link">resume</li>
//           </Link>
//         </ul>
//       </div>
//       {/* <div className="flex sm:flex-row flex-col items-center gap-x-8 gap-y-[6vw]">
//         <ul className="flex flex-row text-[3.5vw] sm:text-[1.11vw] items-center justify-center gap-x-[2.5vw] sm:gap-x-8">
//           <Link>
//             {" "}
//             <li className="link">case studies</li>
//           </Link>
//           <Link>
//             {" "}
//             <li className="link">resume</li>
//           </Link>
//         </ul>
//       </div> */}
//       <div className="md1:flex hidden items-center gap-x-8">
//         <ul className="flex items-center gap-x-2 md:gap-x-5 lg:gap-x-8">
//           <Link to="https://www.behance.net/sarthakg03" target="_blank">
//             {" "}
//             <li className="link">behance</li>
//           </Link>
//           <Link
//             to="https://www.linkedin.com/in/sarthakgoyal-uixchef/"
//             target="_blank"
//           >
//             {" "}
//             <li className="link">linkedin</li>
//           </Link>
//           <Link
//             to="https://www.instagram.com/uix.chef?igsh=MXM1MXZxZ2o2bmkxZw=="
//             target="_blank"
//           >
//             {" "}
//             <li className="link">threads</li>
//           </Link>
//           <Link to="https://uixchef.medium.com/" target="_blank">
//             {" "}
//             <li className="link">medium</li>
//           </Link>
//           <Link
//             to="https://www.instagram.com/uix.chef?igsh=MXM1MXZxZ2o2bmkxZw=="
//             target="_blank"
//           >
//             {" "}
//             <li className="link">instagram</li>
//           </Link>
//         </ul>
//         <button className="button w-full sm:w-fit text-black font-semibold sm:text-[0.97vw] text-[3.58vw] bg-[#FFFFFF]  px-[1.6vw] py-[3vw] sm:py-[0.83vw] rounded-[12px] ">
//           hey@uixchef.com
//         </button>
//       </div>
//     </div>
//   );
// };

// export default FooterDown;

import React from "react";
import logo from "../../../assets/images/logo.svg";
import "./footer.css";
import { Link } from "react-router-dom";

const FooterDown = ({ userData, handleCase, handleCaseLogo }) => {
  return (
    <div className=" rounded-tl-[16px] sm:rounded-tl-[32px] mt-[12.3vw] sm:mt-[1.94vw] rounded-tr-[16px]  sm:rounded-tr-[32px] pt-[6vw] pb-[3vw] sm:pt-[2.78vw] sm:pb-[1.35vw] sm:px-[1.67vw] px-[11vw] text-[#FFFFFF] text-[3.5vw] sm:text-[1.11vw] font-inter font-medium bg-[#121212]">
      <div className="flex sm:flex-row flex-col justify-between  sm:items-center gap-y-[6vw]">
        <div className="flex sm:flex-row flex-col items-start sm:tems-center gap-x-[3vw] md:gap-x-14 gap-y-[6vw]">
          <div onClick={() => handleCaseLogo()} className="cursor-pointer">
            {" "}
            <img
              src={"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280409/images/logo_nkalkx.svg"}
              alt="Logo"
              className=" cursor-pointer pointer-events-none"
            />{" "}
          </div>{" "}
          <ul className="sm:flex sm:flex-row hidden sm:items-center gap-y-[2vw] gap-x-[2vw]">
            <Link onClick={() => handleCase()}>
              {" "}
              <li className="link">case studies</li>
            </Link>
            <Link to={userData.resume} target="_blank">
              {" "}
              <li className="link">resume</li>
            </Link>
          </ul>
        </div>
        <div className="flex sm:flex-row flex-row items-start justify-between sm:items-center gap-x-8 gap-y-[6vw]">
          <ul className="flex sm:flex-row flex-col text-[3.5vw] sm:text-[1.11vw] items-start sm:items-center justify-center gap-y-[2.5vw] gap-x-2 md:gap-x-5 lg:gap-x-8">
            <Link to="https://www.behance.net/sarthakg03" target="_blank">
              {" "}
              <li className="link">behance</li>
            </Link>
            <Link
              to="https://www.linkedin.com/in/sarthakgoyal-uixchef/"
              target="_blank"
            >
              {" "}
              <li className="link">linkedin</li>
            </Link>
            <Link
              to="https://www.threads.net/@uix.chef"
              target="_blank"
            >
              {" "}
              <li className="link">threads</li>
            </Link>
            <Link to="https://uixchef.medium.com/" target="_blank">
              {" "}
              <li className="link">medium</li>
            </Link>
            <Link
              to="https://www.instagram.com/uix.chef?igsh=MXM1MXZxZ2o2bmkxZw=="
              target="_blank"
            >
              {" "}
              <li className="link">instagram</li>
            </Link>
          </ul>
          <ul className="flex sm:hidden flex-col items-end sm:items-center text-[3.5vw] gap-y-[2.5vw] gap-x-8">
            <Link onClick={() => handleCase()}>
              {" "}
              <li className="link">case studies</li>
            </Link>
            <Link to={userData.resume} target="_blank">
              {" "}
              <li className="link">resume</li>
            </Link>
          </ul>
          <Link to="mailto:hey@uixchef.com" className="hidden sm:block">
            <button className="button w-full sm:w-fit text-black font-semibold sm:text-[0.97vw] text-[3.58vw] bg-[#FFFFFF]  px-[1.6vw] py-[3vw] sm:py-[0.83vw] rounded-[12px] ">
              hey@uixchef.com
            </button>
          </Link>{" "}
        </div>
      </div>
      <Link to="mailto:hey@uixchef.com" className="sm:hidden mt-[4vw] block rounded-[12px] mb-[1vw]">
        <button className="button w-full sm:w-fit text-black font-semibold sm:text-[0.97vw] text-[3.58vw] bg-[#FFFFFF]  px-[1.6vw] py-[3vw] sm:py-[0.83vw] rounded-[12px] ">
          hey@uixchef.com
        </button>
      </Link>{" "}
      <div className=" border-[#FFFFFF1A] sm:border-t-[1px] pt-[1.38vw] text-[#FFFFFF] font-inter font-medium text-[2vw] sm:text-[0.83vw] mt-[1.4vw] text-center opacity-50">
        <h1>
          © 2024 Sarthak Goyal. "Good design is good business." -- Thomas Watson
          Jr
        </h1>
      </div>
    </div>
  );
};

export default FooterDown;
