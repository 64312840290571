import { useEffect, useState } from "react";

export function useEdit(setData) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [loading, setloading] = useState(false);

  const handleEdit = async (newData,index) => {
    try {
      closeModal();
      setData((prev) => {
        const arr = [...prev];
        arr[index] = newData
        return arr;             

      });
      setloading(true);
      const response = await fetch(
        `https://sg-portfolio-2-0-server.onrender.com/user/editExperience/${index}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("user")}`,
          },
          body: JSON.stringify(newData),
        }
      );
      if (!response.ok) {
        throw new Error("Failed Updation");
      }
    } catch (error) {
      alert("Internal server error: ", error.message);
    } finally {
      setloading(false);
    }
  };
  

  return {
    isModalOpen,
    openModal,
    closeModal,
    loading,
    handleEdit
  };
}