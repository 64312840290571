import { useLocation } from "react-router-dom";
import Resume from "./resume";

export default function ResumeFull() {
  const location = useLocation();
  const detail = location.state.field;
  return (
    <div className="text-center">
      <Resume detail={detail}></Resume>
    </div>
  );
}