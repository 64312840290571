import React from "react";
import Marquee from "react-fast-marquee";
import skills from "../../../assets/images/skills.svg";
import { solutionImages } from "./data/SolutionData";

const SolutionsMarq = ({ directionMarq, show }) => {
  return (
    <Marquee autoFill speed={50} direction={directionMarq} className="">
      {solutionImages?.map((e, index) => (
        <div kry={index}
          className={`bg-[#3D8D75] rounded-[2vw] sm:rounded-[0.83vw] flex gap-x-2 h-[10.25vw] sm:h-[3.33vw] aspect-auto  items-center border-[1px] border-[#FFFFFF80] p-[1.53vw] sm:p-[0.49vw] mr-[1.67vw] sm:mr-[0.45vw] ${show}`}
        >
          <img
            src={e.img}
            alt=""
            className="w-[7.17vw] sm:w-[2.43vw] aspect-square"
          />
          <h1 className="font-manrope text-[#FFFFFF] font-medium text-[3vw] sm:text-[0.97vw]">
           {e.title}
          </h1>
        </div>
      ))}
    </Marquee>
  );
};

export default SolutionsMarq;
