
import React, { useState } from 'react';
import Modal from './modal';

export function UpdateModal({ isOpen, onClose, onUpdate, fields }) {
  const [formData, setFormData] = useState(
    fields.reduce((acc, field) => ({ ...acc, [field.name]: '' }), {})
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdate(formData);
    setFormData(fields.reduce((acc, field) => (field.name !== "projects" ? { ...acc, [field.name]: '' } : { ...acc, [field.name]: [] }), {}));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2 className="text-xl font-bold mb-4">Update Data</h2>
      {fields.length > 4 ?
        <form onSubmit={handleSubmit} className="flex flex-wrap gap-2">
          {fields.map((field) => (
            <div key={field.name}>
              <label htmlFor={field.name} className="block mb-1">{field.label}</label>
              {field.name !== 'description' ?
                <input
                  required
                  type="text"
                  id={field.name}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleInputChange}
                  className="w-full px-2 py-0.5 border rounded"
                />
                : <div className='flex flex-col w-full gap-2'>
                  <textarea
                    required
                    type="text"
                    id={field.name}
                    name={field.name}
                    value={formData[field.name]}
                    onChange={handleInputChange}
                    className=" px-2 py-0.5 border rounded"
                    rows="4" cols="43"
                  />
                  <button
                    type="submit"
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                  >
                    Submit Update
                  </button>
                </div>
              }
            </div>
          ))}
        </form> :
        <form onSubmit={handleSubmit} className="space-y-4">
          {fields.map((field) => (
            <div key={field.name}>
              <label htmlFor={field.name} className="block mb-1">{field.label}</label>
              <input
                required
                type="text"
                id={field.name}
                name={field.name}
                value={formData[field.name]}
                onChange={handleInputChange}
                className="w-full px-2 py-0.5 border rounded"
              />
            </div>
          ))}
          <button
            type="submit"
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          >
            Submit Update
          </button>
        </form>
      }
    </Modal>
  );
}