import { useLocation } from "react-router-dom";
import PersonalImage from "./personalImage";
import { useUpdate } from "../../../hooks/update"
import React from 'react';
import { UpdateModal } from "./updateModel";
import Spinner from "../../utils/Spinner";


export default function PersonalImageFull() {
    const location = useLocation();
    const details = location.state.field;

    const { data: PI, isModalOpen, openModal, closeModal, handleUpdate, loading, setData } = useUpdate(
        details,"PersonalImages"
      );
    
      const updateFields = [
        { name: 'imageUrl', label: 'Image URL' }
      ];

      if(loading){
        return (<Spinner/>);
      }

    return(<div className="text-center">
                <h1 className="font-medium text-white text-4xl my-4">Personel Images</h1>
                <button onClick={openModal} type="button" className="truncate w-2/3 mx-4 mt-4 py-4 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">UPDATE</button>
                <div className="flex flex-wrap gap-4 m-4">
                    {PI.map((data,index)=>(<PersonalImage key={index} id={index} data={data} set={setData}/>))}
                </div>
                <UpdateModal 
                     isOpen={isModalOpen} 
                     onClose={closeModal} 
                     onUpdate={(newData) => {
                      handleUpdate(newData);
                    }}
                     fields={updateFields}
                />
           </div>)
}
