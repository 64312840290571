// import skill1 from "../../../../assets/images/skilla (1).svg"
// import skill2 from "../../../../assets/images/skilla (2).svg"
// import skill3 from "../../../../assets/images/skilla (3).svg"
// import skill4 from "../../../../assets/images/skilla (4).svg"
// import skill5 from "../../../../assets/images/skilla (5).svg"
// import skill6 from "../../../../assets/images/skilla (6).svg"
// import skill7 from "../../../../assets/images/skilla (7).svg"
// import skill8 from "../../../../assets/images/skilla (8).svg"

export const skillData=[
    {
        img:"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280427/images/skilla_7_iqsqlx.svg",
        title:"UI/UX"
    },
    {
        img:"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280426/images/skilla_6_v4cjzy.svg",
        title:"Product Mindset"
    },
    {
        img:"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280425/images/skilla_5_c21vjv.svg",
        title:"Interaction Design"
    },
    {
        img:"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280424/images/skilla_4_zc63dh.svg",
        title:"Interactive Prototyping"
    },
    {
        img:"https://res.cloudinary.com/dbrhoa186/image/upload/v1722466124/Group_101007_kl2b2g.svg",
        title:"Design System"
    },
    {
        img:"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280420/images/skilla_2_lp8exh.svg",
        title:"Usability Testing"
    },
    {
        img:"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280426/images/skilla_6_v4cjzy.svg",
        title:"Product Strategy"
    },
    {
        img:"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280427/images/skilla_8_zwfnzb.svg",
        title:"Data Analysis"
    },
    {
        img:"https://res.cloudinary.com/dbrhoa186/image/upload/v1722466213/Group_101010_c9cmyj.svg",
        title:"Art Direction"
    },
]