import { useState } from "react";

export function useProUpdate(oid) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [loading, setloading] = useState(false);

  const handleAddUpdate = async (newData) => {
    try {
      setloading(true);
      await fetch(`https://sg-portfolio-2-0-server.onrender.com/user/updateExperienceproject/${oid}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user")}`,
        },
        body: JSON.stringify(newData),
      });
      closeModal();
    } catch (error) {
      alert("Internal server error: ", error.message);
    }
    finally{
      setloading(false);
    }
  };

  const handleRemove = async (id, index) => {
    try {
      setloading(true);
      await fetch(
        `https://sg-portfolio-2-0-server.onrender.com/user/deleteExperienceproject/${id}/${index}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("user")}`,
          },
        }
      );
    } catch (error) {
      alert("Internal server error: ", error.message);
    }
    finally{
      setloading(false);
    }
  };

  return {
    handleAddUpdate,
    openModal,
    closeModal,
    isModalOpen,
    handleRemove,
    loading
  };
}