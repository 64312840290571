import React from "react";
//import pen from "../../../assets/images/pen.svg";
import Marquee from "react-fast-marquee";
import EditorialMraq from "./EditorialMraq";


const Editorial = ({ userData }) => {
  return (
    <div className="mx-0 sm:mx-[5.56vw] mt-[23.58vw] sm:mt-[9.44vw]">
      <div className="flex justify-center items-center gap-x-[2.56vw] sm:gap-x-[0.69vw]">
        <img
          src={"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280412/images/pen_pheyur.svg"}
          className="w-[5.12vw] sm:w-[1.67vw] aspect-square"
          alt=""
        />
        <h1 className="font-inter text-[3vw] sm:text-[1.11vw] font-semibold  uppercase text-[#FFFFFF80]">
          Editorial Space
        </h1>
      </div>{" "}
      <div className="mt-[10.25vw] sm:mt-[2.78vw] mx-[5.56vw]">
        <Marquee autoFill speed={40}  pauseOnHover={true}>
          {userData.editorial?.map((e, index) => (
            <EditorialMraq
            key={index}
              content={e.content}
              image={e.image}
              title={e.title}
              link={e.link}
            />
          ))}
        </Marquee>
      </div>
    </div>
  );
};

export default Editorial;