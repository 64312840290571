import React from "react";
import skills from "../../../assets/images/skills.svg";
import SolutionsMarq from "./SolutionsMarq";
import "./Workspace.css"

const Solutions = () => {
  return (
    <div className="bg-[#FDBF45] border-[1px] border-[#FFFFFF80]  rounded-[12px] sm:rounded-[20px] sm:pt-[1.38vw] sm:pb-[2vw] py-[2.56vw]  relative overflow-hidden  h-[31vw] sm:h-full w-full">
      <div className="font-inter font-medium text-[3vw] sm:text-[0.97vw] text-[#282828] flex items-center justify-center gap-x-2 ">
        <img
          src={skills}
          alt=""
          className="w-[3.59vw] sm:w-[1.11vw] aspect-square"
        />
        <h1>Services</h1>
      </div>
      <h1 className="font-inter font-semibold sm:text-[1.11vw] text-[3.58vw] text-[#000000] text-center">
        Solutions Suite
      </h1>
      <div className="flex flex-col justify-center gap-y-[0.7vw] mt-[4.1vw] sm:mt-[2.15vw]">
        <SolutionsMarq directionMarq={"right"} show={"block"} />
        <SolutionsMarq directionMarq={"left"} show={"sm:flex hidden"} />
      </div>
      <div className="absolute bg-[#FDBF45] bottom-0 sm:bottom-4 z-50 -left-4 blur-md h-3/4 w-16"></div>
      <div className="absolute bg-[#FDBF45] bottom-0 sm:bottom-4 z-50 -right-4 blur-md h-3/4 w-16"></div>
      <div className="absolute hidden sm:block bg-[#FDBF45] bottom-0 z-50  h-7 blur-md rounded-3xl w-full"></div>
    </div>
  );
};

export default Solutions;
