import React from "react";
import book from "../../../assets/images/book.svg";
import mail from "../../../assets/images/mail.svg";
import calender from "../../../assets/images/calender.svg";
import { Link } from "react-router-dom";

const ContactBox = () => {
  return (
    <div className="bg-[#4085E7] rounded-[12px] sm:rounded-[20px] border-[1px] border-[#FFFFFF80] pt-[1.38vw] pb-[1.55vw] px-[1.79vw] sm:px-[1.2vw] ">
      <div className="border-b-[1px] border-[#FFFFFF0F]">
        <div className="flex justify-center items-center gap-x-2 font-inter font-medium text-[#CCCCCC] text-[3vw] sm:text-[0.97vw]">
          <img
            src={book}
            alt=""
            className="w-[3.59vw] sm:w-[1.11vw]  aspect-square"
          />
          <h1>Let’s Work Together</h1>
        </div>
        <h1
          className={`font-inter font-semibold text-center text-[#E6E6E6] sm:text-[1.11vw] text-[3vw] ${
            window.innerWidth > 635 ? null : "leading-[3.72vw]"
          }`}
        >
          Let's Make Magic Happen Together!
        </h1>
      </div>
      <div className="flex sm:flex-row flex-col justify-center items-center gap-y-[1.53vw] sm:gap-x-[0.55vw] w-full  sm:mt-[1.67vw] mt-[3vw]">
        <Link to="mailto:hey@uixchef.com" className="w-full">
          <div className="bg-[#FFFFFF2E] border-[1px] border-[#FFFFFF80] rounded-[2vw] sm:rounded-[0.83vw] flex justify-center items-center gap-x-2 w-full py-[2vw] sm:py-[1.11vw] px-[2.5vw] sm:px-[0.6vw]">
            <img
              src={mail}
              alt=""
              className="sm:h-[0.99vw] h-[3.2vw] aspect-auto"
            />
            <h1 className="font-inter font-medium text-[#FFFFFF] text-[3vw] sm:text-[0.8vw] md:text-[0.9vw]">
              Email Me
            </h1>
          </div>
        </Link>
        <Link className="w-full">
          {" "}
          <div className="bg-[#FFFFFF2E] border-[1px] border-[#FFFFFF80] rounded-[2vw] sm:rounded-[0.83vw] flex justify-center items-center gap-x-2 w-full py-[2vw] sm:py-[1.11vw] px-[2.5vw] sm:px-[0.6vw]">
            <img
              src={calender}
              alt=""
              className=" sm:h-[0.99vw] h-[3.2vw] aspect-auto"
            />
            <h1 className="font-inter font-medium text-[#FFFFFF] text-[3vw] sm:text-[0.8vw] md:text-[0.9vw]">
              Schedule a Call
            </h1>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ContactBox;
