import React, { useEffect, useState } from "react";
//import exp from "../../../assets/images/experience.svg";
import ExperienceCard from "./ExperienceCard";
import { motion } from "framer-motion";
import ExpCardImg from "./ExpCardImg";
import "./Experience.css";
import { Link } from "react-router-dom";

const Experience = ({ userData }) => {
  const [ExperienceData, setExperienceData] = useState([]);
  const [toggleExperience, setToggleExperience] = useState([]);

  const handleMouseIN = (e) => {
    const temp = Array(ExperienceData.length).fill(false);
    if (toggleExperience[e] !== true) temp[e] = true;
    console.log(e, "array ", temp);
    setToggleExperience(temp);
  };

  useEffect(() => {
    if (ExperienceData) {
      setToggleExperience(Array(ExperienceData?.length).fill(false));
    }
  }, [ExperienceData]);

  useEffect(() => {
    if (userData) {
      setExperienceData(userData.experience);
    }
  }, [userData]);

  const getLuminance = (hex) => {
    hex = hex.replace("#", "");

    // Convert hex to RGB
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Calculate luminance
    return (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  };

  const getTextColor = (hex) => {
    const luminance = getLuminance(hex);
    return luminance > 0.5 ? "black" : "white";
  };

  const isInPoints = (description) => {
    return description.includes("4509");
  };

  const parseDescription = (description, color) => {
    const elements = [];
    const parts = description.split(/(\/para|\/points)/);

    let currentTag = "";
    parts.forEach((part) => {
      if (part === "/para" || part === "/points") {
        currentTag = part;
      } else {
        if (currentTag === "/points") {
          const points = part.split("/break").filter((point) => point.trim());
          elements.push(
            <ul style={{ color: getTextColor(color) }}>
              {points.map((point, index) => (
                <li className="ml-4 my-2 sm:mb-4 list-disc" key={index}>
                  {point.trim()}
                </li>
              ))}
            </ul>
          );
        } else if (currentTag === "/para") {
          elements.push(
            <p style={{ color: getTextColor(color) }}>{part.trim()}</p>
          );
        }
      }
    });

    return elements;
  };
  return (
    <div className="mx-[4.1vw] sm:mx-[6.5vw] lg:mx-[5.54vw] mt-[27.69vw] sm:mt-[13.89vw]">
      <div className="text-center sm:text-left">
        <h3 className="font-inter text-[#FFFFFF80] font-semibold uppercase sm:text-[18px] text-[3vw]">
          From Roles to Impacts
        </h3>
        <div className="flex items-center justify-center sm:justify-start gap-x-[4.61vw] sm:gap-x-4">
          <h1 className="font-inter text-[#FFFFFF] font-semibold lowercase sm:text-[52px] text-[9.25vw]">
            Work experience
          </h1>
          <Link to={userData.resume} target="_blank">
            {" "}
            <img
              src={"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280397/images/experience_f2yebw.svg"}
              className="sm:w-[36px] w-[7.69vw] sm:mt-[6px] aspect-square"
              alt=""
            />
          </Link>
        </div>
        <div className="sm:flex hidden items-center justify-between mt-6">
          <p className="font-inter text-[#FFFFFF80] font-medium text-[3vw] sm:text-[1.38vw]">
            I've been designing things since 2020, which <br /> is literally my
            biggest hobby, what I do best, <br /> and luckily also what I do for
            a living.
          </p>
          <p className="font-inter text-[#FFFFFF80] font-medium text-[3vw] sm:text-[1.38vw]">
            One important thing about me is that I only work on those products
            where I feel <br /> that I will be an important assets to the
            organization, because I need to <br /> give 200% in what I do.
          </p>
        </div>
        <div className="flex flex-col gap-y-4 sm:hidden items-center justify-between mt-6">
          <p className="font-inter leading-[4.6vw] text-[#FFFFFF80] font-medium text-[2.97vw] xss:text-[3vw] sm:text-[1.38vw]">
            I've been designing things since 2020, which is literally my <br />
            biggest hobby, what I do best, and luckily also what I do for a{" "}
            <br /> living.
          </p>
          <p className="font-inter leading-[4.6vw] text-[#FFFFFF80] font-medium text-[2.97vw] xss:text-[3vw] sm:text-[1.38vw]">
            One important thing about me is that I only work on <br /> those
            products where I feel that I will be an important <br /> assets to
            the organization, because I need to give <br /> 200% in what I do.
          </p>
        </div>
      </div>
      <div className="flex flex-col w-full gap-y-[3vw] sm:gap-y-5 mt-[13.33vw] sm:mt-[6.94vw]">
        {ExperienceData &&
          ExperienceData?.map((e) => (
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
              key={ExperienceData.indexOf(e)}
              className="w-full"
            >
              <div
                onClick={() => handleMouseIN(ExperienceData.indexOf(e))}
                className="expDiv"
              >
                <ExperienceCard
                  color={e.color}
                  company={e.companyName}
                  logo={e.logo}
                  period={e.period}
                  pattern={e.pattern}
                  role={e.role}
                  text={e.textColor}
                  pattenMobile={e.mobilePattern}
                />
              </div>
              <motion.div
                initial={{ opacity: 0, display: "none" }}
                animate={{
                  opacity: toggleExperience[ExperienceData.indexOf(e)] ? 1 : 0,
                  display: toggleExperience[ExperienceData.indexOf(e)]
                    ? "block"
                    : "none",
                }}
                transition={{ duration: 0.3 }}
                className={` ${
                  toggleExperience[ExperienceData.indexOf(e)]
                    ? "block"
                    : "hidden"
                } sm:w-[87.769vw] w-[91.79vw] aspect-auto sm:aspect-auto rounded-2xl min-h-fit border-t-2 partner border-dashed`}
                style={{ backgroundColor: e.color }}
              >
                <div className="flex sm:flex-row flex-col justify-between items-start sm:px- sm:pt-7  sm:pb-3 pt-4 w-full h-full">
                  {/* <div
                    className={` ${
                      e.projects.length === 0 ? "w-full" : "sm:w-[52.5vw]"
                    }  font-inter font-light text-[4vw] leading-[5.5vw] sm:leading-[2vw] sm:text-[1.38vw] px-4 sm:px-5`}
                  >
                    {isInPoints(e.description) ? (
                      <ul style={{ color: getTextColor(e.color) }}>
                        {e.description.split("4509").map(
                          (point, index) =>
                            point.trim() && (
                              <li className="ml-4 mb-2 sm:mb-4 list-disc" key={index}>
                                {point.trim()}
                              </li>
                            )
                        )}
                      </ul>
                    ) : (
                      <p style={{ color: getTextColor(e.color) }}>
                        {e.description}
                      </p>
                    )}
                  </div> */}
                  <div
                    className={` ${
                      e.projects.length === 0 ? "w-full" : "sm:w-[52.5vw]"
                    }  font-inter font-light text-[4vw] leading-[5.5vw] sm:leading-[2vw] sm:text-[1.38vw] px-4 sm:px-5`}
                  >
                    {parseDescription(e.description, e.color)}
                  </div>
                  <div
                    className={`${
                      e.projects.length === 0 ? "w-0" : "sm:w-[47.5vw]"
                    }  h-full flex flex-wrap gap-[16px] sm:justify-start justify-between mt-4 mb-4 sm:mb-0 px-4 sm:mt-0`}
                  >
                    {e.projects?.map((f) => (
                      <ExpCardImg url={f.url} title={f.title} />
                    ))}
                  </div>
                </div>
              </motion.div>
            </motion.div>
          ))}
      </div>
    </div>
  );
};

export default Experience;
