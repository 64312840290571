import React from 'react';
import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./footer.css";

const FooterUp = () => {
  return (
    <div className="relative mx-[4.1vw] sm:mx-0 bg-black text-white bg-cover bg-center bg-no-repeat flex items-center justify-center  pb-[5vw] sm:py-[5vw] border border-[#FFFFFF1A] rounded-[16px] sm:rounded-[32px]" style={{ backgroundImage: 'url("https://res.cloudinary.com/dbrhoa186/image/upload/v1711644251/image32_kuz92q.svg")' }}>
      <div className="flex flex-col gap-[2vw] sm:gap-[0vw] items-center ">
        <div className="flex sm:flex-row flex-col justify-between w-2/3 mx-auto gap-[-5vw]  sm:gap-[2vw] items-center font-inter font-bold">
          <h1 className="text-[25.64vw] sm:text-[10vw] ">Let's</h1>
          <span className='my-[-20px]'>
            <img src={"https://res.cloudinary.com/dbrhoa186/image/upload/v1711644356/image_31_xqujsj.svg"} className="aspect-[2.14vw] w-[50vw] pointer-events-none" alt="" />
          </span>
          <h1 className="text-[25.64vw] sm:text-[10vw] ">Talk</h1>
        </div>
        <div className="w-full sm:hidden block mt-[-15px] sm:mt-[3vw] text-[#FFFFFF80] font-inter font-light opacity-50">
          <p className="text-center text-[3.58vw] sm:text-[1.5vw] leading-[5.64vw]">
            Good design can make a real difference in the <br /> world. I love the
            challenge of taking someone's <br /> vision and turning it into a reality
          </p>
        </div>
        <div className="w-full sm:block hidden sm:mt-[-1.5vw] text-[#FFFFFF80] font-inter font-light opacity-50">
          <p className="text-center text-[3.58vw] sm:text-[1.38vw] leading-[2vw]">
            Good design can make a real difference in the world. I love the
            challenge of <br /> taking someone's vision and turning it into a reality
          </p>
        </div>
        {/* <div id="div1" className="mt-[3vw] w-full text-ellipsis flex items-center justify-center"> */}
          <Link
            target="_blank"
            to="https://wa.me/919829080981"
            className="text-black mt-[3vw] sm:mt-[2vw] btn bg-white  px-[4vw] sm:px-[3vw] w-full sm:w-[14vw] py-[2vw] sm:py-[0.7vw] text-[5.12vw] sm:text-[1.11vw] font-semibold justify-center rounded-[12px]  flex items-center gap-x-1 transition duration-300 ease-in-out "
          >
            WhatsApp
            <FaWhatsapp className="btn-w" />
          </Link>
        {/* </div> */}
      </div>
    </div>
  );
}

export default FooterUp;