import React, { useEffect, useRef, useState } from "react";
import Marquee from "react-fast-marquee";
// import bannerMarq from "../../../assets/images/bannerMarq.svg";

const BannerMarq = ({ data, directionMarq }) => {
  const [speed, setSpeed] = useState(100);

  const handleIn = () => {
    setSpeed(20);
  };
  const handleOut = () => {
    setSpeed(100);
  };

  return (
    <Marquee
      autoFill={true}
      direction={directionMarq}
      speed={40}
      className="-mb-8"
      pauseOnHover={true}
    >
      {data.map((e) => (
        <div
          key={data.indexOf(e)}
          className="flex items-center justify-center gap-x-[2.5vw]"
        >
          <h1
            // onMouseEnter={handleIn}
            // onMouseLeave={handleOut}
              className={`text-[#66666670] ${e.color} font-impact font-normal text-[8.2vw] sm:text-[5.55vw]  uppercase`}
          >
            {e.name}
          </h1>
          <img
            src={"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280381/images/bannerMarq_wlscxu.svg"}
            alt=""
            className="mr-[2.5vw] w-[7.17vw] sm:w-[4.17vw] aspect-square mt-1 pointer-events-none"
          />
        </div>
      ))}

      {/* <Comp speed={speed} handleIn={handleIn} handleOut={handleOut} data={data}/> */}
    </Marquee>
  );
};

export default BannerMarq;
