import React from "react";
import "./Editorial.css";
import { Link } from "react-router-dom";


const EditorialMraq = ({ content, image, title,link }) => {
  return (
    <Link to={link} target="_blank" className="border-[#FFFFFF0F] border-[1px] rounded-2xl sm:rounded-xl md1:rounded-2xl bg-[#111111] w-[54.35vw] sm:w-[18.75vw] h-[20.51vw] sm:h-[6.67vw] aspect-auto sm:p-[0.83vw] p-[2.56vw] flex items-center gap-x-[3vw] sm:gap-x-[0.83vw] mr-[7.16vw] sm:mr-[1.94vw]">
      <img
        src={image}
        className="h-full w-[15.38vw] sm:w-[5vw] aspect-square rounded-xl sm:rounded-lg md1:rounded-xl bg-white object-cover"
        alt=""
      />
      <div className="font-inter flex flex-col justify-between gap-y-[2vw] sm:gap-y-[0.3vw]">
        <h1 className="font-semibold text-[#FFFFFF] xs:h-[6vw] xmd:h-[5.59vw] sm:h-[2.7vw] sm1:h-[2.5vw] md1:h-[2.2vw] leading-[12px] xxs:leading-[15px] xss:leading-[17px] sm:leading-[10px] md1:leading-[1.1vw] text-[3vw] sm:text-[1.11vw]  text-clamp">
          {title}
        </h1>
        <p className="font-normal text-[#5F5F5F] h-[5.69vw]  sm:h-[2vw] text-[2.3vw] sm:text-[0.83vw] leading-[2.79vw] sm:leading-[1vw] text-clamp">
          {content}
        </p>
      </div>
    </Link>
  );
};

export default EditorialMraq;
