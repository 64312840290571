import React from "react";
import Marquee from "react-fast-marquee";
import partner1 from "../../../assets/images/partner1.svg";
import "./Workspace.css";
import { partnerImages } from "./data/PartnerData";

const PartnerMarq = ({ directionMarq }) => {
  return (
    <Marquee autoFill speed={50} direction={directionMarq}>
      {partnerImages?.map((e, index) => (
        <img
          key={index}
          src={e}
          alt=""
          className="h-[10.25vw] sm:h-[3.33vw] aspect-auto mr-[1.67vw] sm:mr-[0.45vw]"
        />
      ))}
    </Marquee>
  );
};

export default PartnerMarq;

// import React from "react";
// import partner1 from "../../../assets/images/partner1.svg";
// // import "./styles.css"; // Ensure you have the CSS in the correct path

// const PartnerMarq = () => {
//   return (
//     <div className="marquee-container h-[10.25vw] sm:h-[3.33vw] w-full">
//       <div className="marquee">
//         <img
//           src={partner1}
//           alt=""
//           className="h-[10.25vw] sm:h-[3.33vw] aspect-auto mr-[1.67vw] sm:mr-[0.45vw]"
//         />{" "}
//         <img
//           src={partner1}
//           alt=""
//           className="h-[10.25vw] sm:h-[3.33vw] aspect-auto mr-[1.67vw] sm:mr-[0.45vw]"
//         />
//         <img
//           src={partner1}
//           alt=""
//           className="h-[10.25vw] sm:h-[3.33vw] aspect-auto mr-[1.67vw] sm:mr-[0.45vw]"
//         />
//         <img
//           src={partner1}
//           alt=""
//           className="h-[10.25vw] sm:h-[3.33vw] aspect-auto mr-[1.67vw] sm:mr-[0.45vw]"
//         />
//         <img
//           src={partner1}
//           alt=""
//           className="h-[10.25vw] sm:h-[3.33vw] aspect-auto mr-[1.67vw] sm:mr-[0.45vw]"
//         />
//         <img
//           src={partner1}
//           alt=""
//           className="h-[10.25vw] sm:h-[3.33vw] aspect-auto mr-[1.67vw] sm:mr-[0.45vw]"
//         />
//         {/* Repeat as needed */}
//       </div>
//     </div>
//   );
// };

// export default PartnerMarq;
