import React, { useEffect, useState } from "react";
import "./hero.css";
import {motion} from "framer-motion"


const LogoCylinder1 = ({ divWidth }) => {
  const images = [
    "https://res.cloudinary.com/dbrhoa186/image/upload/v1711642253/Webflow_sqahlz.svg",

    "https://res.cloudinary.com/dbrhoa186/image/upload/v1711642283/Figma_wtthsk.svg",

    "https://res.cloudinary.com/dbrhoa186/image/upload/v1711642304/Framer_p63ehe.svg",

    "https://res.cloudinary.com/dbrhoa186/image/upload/v1711642342/Notion_ezyu2a.svg",

    "https://res.cloudinary.com/dbrhoa186/image/upload/v1711642375/Sketch_gjgety.svg",

    "https://res.cloudinary.com/dbrhoa186/image/upload/v1711642376/Spline_zv39p7.svg",

    "https://res.cloudinary.com/dbrhoa186/image/upload/v1711642398/Illustrator_bxk7dr.svg",

    "https://res.cloudinary.com/dbrhoa186/image/upload/v1711642424/Photoshop_rjkdst.svg",
    "https://res.cloudinary.com/dbrhoa186/image/upload/v1711642460/Xd_s4zs6q.svg",
    "https://res.cloudinary.com/dbrhoa186/image/upload/v1711642446/HTML_gw3k9m.svg",
    "https://res.cloudinary.com/dbrhoa186/image/upload/v1711642470/CSS_cb49rs.svg",
    "https://res.cloudinary.com/dbrhoa186/image/upload/v1711642487/JS_b2hhmq.svg",

    "https://res.cloudinary.com/dbrhoa186/image/upload/v1711642497/Tailwind_hnut3l.svg",

    "https://res.cloudinary.com/dbrhoa186/image/upload/v1711642511/Bootstrap_dapcr8.svg",
    "https://res.cloudinary.com/dbrhoa186/image/upload/v1711642521/Balsamiq_bdzdbr.svg",

    "https://res.cloudinary.com/dbrhoa186/image/upload/v1711642376/Spline_zv39p7.svg",

    // "https://res.cloudinary.com/dbrhoa186/image/upload/v1711642424/Photoshop_rjkdst.svg",
    "https://res.cloudinary.com/dbrhoa186/image/upload/v1722191051/Miro_yovm3r.svg",
    "https://res.cloudinary.com/dbrhoa186/image/upload/v1711642460/Xd_s4zs6q.svg",
  ];
  const [wid, setWid] = useState("2000px");
  const frameWidth = "2000px"; // Length of cylinder circumference
  const frameHeight = "130px"; // Cylinder height
  const stripsCount = 18; // Amount of polygons
  const stripWidth = 111.11; // Polygon width
  // const stripWidth = parseInt(frameWidth) / stripsCount; // Polygon width
  const stripRotateY = 360 / stripsCount; // Step rotation degree for each polygon
  const [stripTranslateZ, setStripTranslateZ] = useState();

  useEffect(() => {
    setStripTranslateZ(parseInt(wid) / Math.PI / 2 - 5); // Position of polygons on the Z axis
  }, [wid]);

  // useEffect(() => {
  //   if (window.innerWidth <= 850 && window.innerWidth > 750) setWid("1900px");
  //   else if (window.innerWidth <= 750 && window.innerWidth > 635)
  //     setWid("1600px");
  //   else if (window.innerWidth <= 635 && window.innerWidth > 550)
  //     setWid("1400px");
  //   else if (window.innerWidth <= 550 && window.innerWidth > 475)
  //     setWid("900px");
  //   else if (window.innerWidth <= 475 && window.innerWidth > 400)
  //     setWid("1050px");
  //   else if (window.innerWidth <= 400 && window.innerWidth > 345)
  //     setWid("920px");
  //   else if (window.innerWidth <= 345) setWid("820px");
  // }, [window.innerWidth]);

  useEffect(() => {
    if (divWidth) {
      setWid(divWidth * 2.8); 
    }
  }, [divWidth]);

  
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, delay: 0.8 }}
      viewport={{ once: true }}
      id="container"
    >
      <div className="cylinder-top"></div>
      <div
        id="frame"
        className="h-[130px] w-[]"
        style={{ width: stripWidth + "px" }}
      >
        {/* <div className="border-2 border-white w-full">
            hello
        </div> */}
        <div className="strip h-full">
          {images.map((e, index) => (
            <div
              key={index}
              className={`strip-${index + 1} w-[111.11px]  h-full`}
              style={{
                backgroundPosition: `${index * stripWidth}px 0`,
                transform: `rotateY(${
                  index * stripRotateY
                }deg) translateZ(${stripTranslateZ}px)`,
              }}
            >
              <img
                src={e}
                alt=""
                className={` hover:scale-125 aspect-auto w-[12vw] sm:w-[8vw] md1:w-[7vw] 3xl:w-[12vw] 4xl:w-full`}
              />
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default LogoCylinder1;
