import { useUpdate } from "../../../hooks/update";
import Experience from "./experience";
import { useLocation } from "react-router-dom";
import { UpdateModal } from "./updateModel";
import Spinner from "../../utils/Spinner";

export default function ExperienceFull(){
    const location =useLocation();
    const details = location.state.field;

    const { data, isModalOpen, openModal, closeModal, handleUpdate, loading, setData } = useUpdate(
        details,"Experience"
      );

      const updateFields = [
        { name: 'companyName', label: 'Comapany Name' },
        { name: 'role', label: 'Role' },
        { name: 'period', label: 'Period' },
        { name: 'pattern', label: 'Pattern' },
        { name: 'logo', label: 'Logo(url)' },
        { name: 'color', label: 'Color(hexCode)' },
        { name: 'mobilePattern', label: 'Mobile Pattern' },
        { name: 'textColor', label: 'Text Color(hexcode)' },
        { name: 'description', label: 'Description' }

      ];
      if(loading){
        return (<Spinner/>);
      }

      return <div className="flex flex-col items-center">
        <h1 className="font-medium text-white text-4xl my-4">Experience</h1>
        <button type="button" onClick={openModal} className="truncate w-2/3 mx-4 mt-4 py-4 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5  me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">UPDATE</button>
        <div className="flex flex-col gap-4 my-4 mx-24 flex-wrap">
        
        {data.map((data,index)=>(
            <Experience key={index} obj={data} index={index} set={setData} fields={updateFields}/>
        ))}
    </div>
    <UpdateModal 
        isOpen={isModalOpen} 
        onClose={closeModal} 
        onUpdate={(newData) => {
          handleUpdate(newData);
        }}
        fields={updateFields}
      />
    </div>
}