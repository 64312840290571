import React from 'react'
import FooterUp from './FooterUp'
import FooterDown from './FooterDown'

const Footer = ({handleCase,userData,handleCaseLogo}) => {
  return (
    <div className=" sm:mx-[5.54vw] mt-[23.58vw] sm:mt-[9.44vw]">
      <FooterUp/>
      <FooterDown handleCase={handleCase} handleCaseLogo={handleCaseLogo} userData={userData}/>
    </div>
  )
}

export default Footer
