import React, { useRef, useState, useEffect } from "react";
import Header from "../components/Home/header/Header";
import Hero from "../components/Home/hero/Hero";
import Banner from "../components/Home/banner/Banner";
import Workspace from "../components/Home/workspace/Workspace";
import ProjectSection from "../components/Home/projects/ProjectSection";
import Footer from "../components/Home/footer/Footer";
import Testimonial from "../components/Home/testimonial/Testimonial";
import Snapshot from "../components/Home/snapshot/Snapshot";
import Experience from "../components/Home/experience/Experience";
import Editorial from "../components/Home/editorial/Editorial";
import Protolab from "../components/Home/protolab/Protolab";
import WorkspaceMobile from "../components/Home/workspace/WorkspaceMobile";
import ProjectSectionPhone from "../components/Home/projects/ProjectSectionPhone";

const Home = ({ userData }) => {
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const [status, setStatus] = useState(false);

  const [isScrollbarVisible, setIsScrollbarVisible] = useState(false);

  useEffect(() => {
    const checkScrollbarVisibility = () => {
      // Create a temporary div to measure scrollbar visibility
      const scrollDiv = document.createElement("div");
      scrollDiv.style.overflow = "scroll";
      scrollDiv.style.width = "100px";
      scrollDiv.style.height = "100px";
      scrollDiv.style.visibility = "hidden";
      document.body.appendChild(scrollDiv);

      // Check if the scrollDiv has a visible scrollbar
      const scrollbarVisible =
        scrollDiv.offsetWidth - scrollDiv.clientWidth > 0;
      setIsScrollbarVisible(scrollbarVisible);

      // Clean up the temporary div
      document.body.removeChild(scrollDiv);
    };
    // Initial check on component mount
    checkScrollbarVisibility();

    // Optionally, check again on window resize
    window.addEventListener("resize", checkScrollbarVisibility);
    return () => {
      window.removeEventListener("resize", checkScrollbarVisibility);
    };
  }, []);

  const handleCase = () => {
    if (section1Ref.current) {
      setStatus(true)
      const time=setTimeout(()=>{
        section1Ref.current.scrollIntoView({ behavior: "smooth" });
        setStatus(false)
      },[1000])


      return ()=>clearTimeout(time)
    }
  };

  const handleCaseLogo = () => {
    if (section2Ref.current) {
      section2Ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };


  return (
    <div>
      <div ref={section2Ref}>
        <Header
          handleCase={handleCase}
          userData={userData}
          handleCaseLogo={handleCaseLogo}
          isScrollbarVisible={isScrollbarVisible}
          status={status}
        />
      </div>
      <Hero />
      <Banner />
      <Workspace isScrollbarVisible={isScrollbarVisible} />
      <WorkspaceMobile />
      <Protolab userData={userData} />
      <div ref={section1Ref}>
        <ProjectSection />
        <ProjectSectionPhone />
      </div>
      <Editorial userData={userData} />
      <Experience userData={userData} />
      <Snapshot userData={userData} />
      {/* <Testimonial /> */}
      <Footer
        handleCase={handleCase}
        userData={userData}
        handleCaseLogo={handleCaseLogo}
      />
      {/* <p>Is scrollbar visible? {isScrollbarVisible ? 'Yes' : 'No'}</p> */}
    </div>
  );
};

export default Home;
