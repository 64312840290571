import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import "./Workspace.css";
import flag from "../../../assets/images/flag1.svg"

const CountCard = ({ data }) => {
  const [count, setCount] = useState(0);
  const { ref, inView } = useInView({
    triggerOnce: true, // Animation triggers only once when the component comes into view
    threshold: 0.1, // Adjust this value based on when you want the animation to start
  });

  const value = 4000 / data.number;

  useEffect(() => {
    if (inView && count < data.number) {
      const interval = setInterval(() => {
        setCount((prevCount) => prevCount + 1);
      }, value);

      return () => clearInterval(interval);
    }
  }, [inView, count, data.number]);

  const formatNumber = (num) => {
    return num.toString().padStart(2, '0');
  };

  return (
    <div
      ref={ref}
      className="bg-[#FFBAD3] border-[1px] w-[21.75vw] sm:w-[23%] border-[#FFFFFF80] rounded-[12px] sm:rounded-[14px] flex flex-col justify-center items-center relative -z-50 overflow-hidden"
    >
      <div className="font-inter font-medium text-[8.2vw] sm:text-[3.8vw] text-center flex flex-row-reverse items-center justify-between sm:mt-[0.6vw] relative overflow-hidden w-full h-full">
        {[...Array(count + 1).keys()].map((num) => (
          <div
            key={num}
            className={`number font-manrope font-medium ${num === data.number ? null : "animate"} pr-2.5`}
          >
            {formatNumber(num)}
          </div>
        ))}
        <span className="text-[5.12vw] sm:text-[2.22vw] block pr-[3vw] sm:pr-[0.83vw]">+</span>
      </div>
      <div className="bg-[#EB5A35] rounded-l-full rounded-r-full px-[1vw] sm:px-[0.5vw] py-[0.8vw] sm:py-[0.4vw] flex items-center justify-center gap-x-[0.8vw] sm:gap-x-[0.13vw] w-[90%] mt-[-1.7vw] sm:mt-[-0.83vw] mb-[0.5vw] z-50">
        <img
          src={data.logo}
          alt=""
          className="sm:w-[0.97vw] w-[1.93vw] aspect-square"
        />
        <h1 className="font-inter font-medium text-[2vw] sm:text-[0.69vw]">
          {data.type}{" "}
        </h1>
      </div>
      <div className="absolute bg-[#FFBAD3] w-full h-5 bottom-4 sm:bottom-6 blur-[8px] z-10"></div>
    </div>
  );
};

export default CountCard;
