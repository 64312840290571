import React from "react";
//import gallary from "../../../assets/images/galary.svg";
import Marquee from "react-fast-marquee";
import SnapshotMarq from "./SnapshotMarq";

const Snapshot = ({ userData }) => {
  return (
    <div className="mx-0 sm:mx-[5.56vw] mt-[23.58vw] sm:mt-[9.44vw]">
      <div className="flex justify-center items-center gap-x-[2.56vw] sm:gap-x-[0.69vw]">
        <img
          src={"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280400/images/galary_nnktut.svg"}
          className="w-[5.12vw] sm:w-[1.67vw] aspect-square"
          alt=""
        />
        <h1 className="font-inter text-[3vw] sm:text-[1.11vw]  font-semibold  uppercase text-[#FFFFFF80]">
          snapshot portfolio
        </h1>
      </div>{" "}
      <div className="mt-[10.25vw] sm:mt-[2.78vw] h-[27.69vw] sm:h-[11.39vw] mx-0 sm:mx-[5.56vw]  overflow-y-visible">
        <Marquee autoFill speed={40} className="h-full w-full" pauseOnHover={true}>
          {userData.personalImages?.map((e, index) => (
            <SnapshotMarq
              key={index}
              image={e}
              skew={index % 2===0 ? "-rotate-6" : "rotate-6"}
            />
          ))}
        </Marquee>
      </div>
    </div>
  );
};

export default Snapshot;
