import React from "react";
import { Link } from "react-router-dom";

const ExpCardImg = ({ url,title }) => {
  return (
    <Link to={title} target="_blank" className="sm:w-[12.22vw] w-[35vw] aspect-[1.42] bg-white rounded-2xl">
      <img src={url} alt="" className="w-full h-full object-cover rounded-2xl shadow" />
    </Link>
  );
};

export default ExpCardImg;
