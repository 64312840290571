import React from "react";
import project1 from "../../../assets/images/project1.svg";
import Marquee from "react-fast-marquee";
import { gallaryData } from "./data/GallaryData";

const GallaryMarq = ({ directionMarq }) => {
  return (
    <Marquee
      autoFill
      speed={50}
      direction={directionMarq}
    >

        {gallaryData?.map((e,index)=>(
          <img key={index} src={e} alt="" className="sm:w-[8.75vw] w-[24.1vw] sm:aspect-[1.58] aspect-[1.575] mr-[1.67vw] sm:mr-[0.45vw] rounded-xl" />
        ))}
    </Marquee>
  );
};

export default GallaryMarq;