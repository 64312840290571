import { useEffect, useState } from "react";
import Spinner from "../../utils/Spinner";
export default function Resume({ detail }) {
  const [dataa, setDataa] = useState(detail);
  const [loading, setloading] = useState(false);
  const handleChange = (e) => {
    setDataa(e.target.value);
  };

  const handleClick = async () => {
    try {
        setloading(true);
      const response = await fetch(
        `https://sg-portfolio-2-0-server.onrender.com/user/updateResume`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("user")}`,
          },
          body: JSON.stringify({ resume: dataa }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed Updation");
      }
    } catch (error) {
      alert("Internal server error: ", error.message);
    }
    finally{
        setloading(false);
    }
  };

  useEffect(() => {
    fetch("https://sg-portfolio-2-0-server.onrender.com/user/details", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user")}`,
        "Content-Type": "application/json",
      },
    }).then(async (res) => {
      const response = await res.json();
      setDataa(response.resume);
    });
  }, []);

  if(loading){
    return <Spinner/>
  }

  return (
    <div className=" flex flex-col items-center mt-16">
      <label
        htmlFor="large-input"
        className="block mb-2  font-medium text-gray-900 dark:text-white text-3xl"
      >
        Resume
      </label>
      <input
        type="text"
        onChange={handleChange}
        value={dataa}
        id="large-input"
        className="block w-3/4 mx-10 p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      ></input>
      <button
        type="button"
        onClick={handleClick}
        className=" truncate w-2/3 mx-4 mt-8 py-4 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5  me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
      >
        UPDATE
      </button>
    </div>
  );
}