import React from "react";
import Partners from "./Partners";
import Work from "./Work";
import ContactBox from "./ContactBox";
import Skills from "./Skills";
import Solutions from "./Solutions";
import Gallary from "./Gallary";
import Count from "./Count";

const WorkspaceMobile = () => {
  return (

    <div className="flex sm:hidden flex-col gap-y-[1.53vw] mx-[4.1vw] sm:mx-[5.56vw] mt-[7.17vw] sm:mt-[3.33vw] ">
      <Partners />
      <Skills />
      <div className="flex w-full h-[78.4vw] gap-x-[1.53vw]">
        <div className="w-1/2 h-full justify-between flex flex-col gap-y-[1.53vw]">
          <div className="h-1/2 ">
            <Count />
          </div>
          <div className="h-1/2">
            {" "}
            <ContactBox />
          </div>
        </div>
        <div className="w-1/2 h-full">
          <Work />
        </div>{" "}
      </div>
      <Solutions />
      <Gallary />
    </div>
  );
};

export default WorkspaceMobile;
