import React from "react";
import exp1 from "../../../assets/images/exp1.svg";
import design from "../../../assets/images/design.svg";
import design1 from "../../../assets/images/smallPatern.svg";

const ExperienceCard = ({
  color,
  company,
  logo,
  period,
  role,
  pattern,
  text,
  pattenMobile,
}) => {
  return (
    <div
      className={`sm:h-[11.11vw] h-[25.38vw] aspect-[3.61] sm:aspect-[7.9]  rounded-[12px] sm:rounded-[20px] flex justify-between items-center`}
      style={{ backgroundColor: color }}
    >
      <div className="h-full flex justify-start items-center gap-x-2 sm:gap-x-10 ml-2 sm:ml-5">
        <img
          src={logo}
          className="sm:h-full h-[25.38vw] sm:w-[11.1vw] aspect-[1.13] sm:aspect-square"
          alt=""
        />
        <div
          className=" font-inter sm:leading-6 md1:leading-8"
          style={{ color: text }}
        >
          <h3 className="font-normal text-[3vw] sm:text-[1.67vw]">{company}</h3>
          <h1 className="font-normal font-impact my-0 sm:my-[0.56vw] text-[5.5vw] sm:text-[3.61vw] tracking-normal">
            {role}
          </h1>
          <h5 className="font-normal font--inter text-[2.5vw] sm:text-[1.11vw]">
            {period}
          </h5>
        </div>
      </div>
      {/* <div className="w-[40.6vw] text-right rounded-2xl"> */}
      <img
        src={pattern}
        className="h-[11.11vw] aspect-[3.66] object-cover rounded-r-2xl hidden sm:block"
        alt=""
      />
      <img
        src={pattenMobile}
        className="h-[25.38vw] aspect-[0.45] rounded-r-2xl block sm:hidden"
        alt=""
      />
      {/* </div> */}
    </div>
  );
};

export default ExperienceCard;