import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ClickableDiv = ({field,user}) => {
    const navigate = useNavigate();

    const handleClick = async ()=>{
        navigate(`/admin/${field}`,{state:{field: user[field]}})
    }
  return (
    <div
      className="break-words bg-red-400 hover:bg-red-500 text-white font-bold py-6 px-6 rounded-lg shadow-md cursor-pointer transition duration-300 ease-in-out"
      onClick={handleClick}>
      {field!=="personalImages"?field.toUpperCase():"PERSONAL IMAGES"}
    </div>
  );
};

export default ClickableDiv;