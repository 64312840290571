import React from 'react'
import ClickableDiv from './clickableDiv';


const Dasboard = ({logoutHandler,getUser}) => {
  return (
    <div className=' flex flex-col text-white text-5xl p-4 gap-28'>
      <div className='flex justify-between'>
        <h1>Dashboard</h1>
        <h1 onClick={()=>logoutHandler()} className='cursor-pointer text-red-400'>logout</h1>
      </div>
      <div className="container mx-auto p-4">
        <div className="flex flex-col gap-12">
        {["resume","protolab","editorial","experience","personalImages"].map((field,index) => (
          <ClickableDiv key={index} field={field} user={getUser} />
        ))}
        </div>
      </div>
    </div>
  )
}

export default Dasboard
