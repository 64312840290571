import React, { useEffect, useState } from "react";
//import protolab from "../../../assets/images/protolab.svg";
import Marquee from "react-fast-marquee";
import ProtolabMarq from "./ProtolabMarq";
// import Marquee, { Motion, randomIntFromInterval } from "react-marquee-slider";

const Protolab = ({ userData }) => {
  const [speed, setSpeed] = useState(150);

  const inHandler = () => {
    setSpeed(20);
  };
  const outHandler = () => {
    setSpeed(50);
  };
  const changeSpeed = () => {
    setSpeed(20);
  };

  return (
    <div className="mx-0 sm:mx-[5.56vw] mt-[23.58vw] sm:mt-[9.44vw]">
      <div className="flex justify-center items-center gap-x-[2.56vw] sm:gap-x-[0.69vw] ">
        <img
          src={"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280416/images/protolab_nbguo3.svg"}
          className="w-[5.12vw] sm:w-[1.67vw] aspect-square"
          alt=""
        />
        <h1 className="font-inter text-[3vw] sm:text-[1.11vw] font-semibold  uppercase text-[#FFFFFF80]">
          interactive protolab{" "}
        </h1>
      </div>{" "}
      <div
        className="mt-[10.25vw] sm:mt-[2.78vw] mx-0 sm:mx-[5.56vw]"
        // onMouseEnter={() => inHandler()}
        // onMouseLeave={() => outHandler()}
      >
        <Marquee autoFill speed={50} pauseOnHover={true}>
          {userData.protolab?.map((e, index) => (
            <ProtolabMarq
              key={index}
              image={e.image}
              title={e.title}
              link={e.link}
              inHandler={inHandler}
              outHandler={outHandler}
            />
          ))}
        </Marquee>
      </div>
    </div>
  );
};

export default Protolab;
