import { useDelete } from "../../../hooks/delete";
import Spinner from "../../utils/Spinner";

export default function Editorial({ title, content, image, index, set }) {
  const { handleUpdate, loading } = useDelete("Editorial");

  const handleClick = () => {
    handleUpdate(index);
    set((prev) => prev.filter((_, i) => i !== index));
  };
  if (loading) {
    return <Spinner/>;
  }

  return (
    <div className="w-72 h-fit text-center bg-white rounded-lg shadow-md overflow-hidden">
      <div className="h-56 overflow-hidden">
        <img src={image} alt={title} className="w-full h-full object-cover" />
      </div>
      <div className="flex flex-col p-4 h-fit ">
        <h1 className="text-lg font-semibold text-gray-800 ">{title}</h1>
        <p>**********</p>
        <div className="h-12 ">
          <p className="overflow-hidden truncate">{content}</p>
        </div>
        <button
          type="button"
          onClick={handleClick}
          className=" focus:outline-none text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
        >
          Delete
        </button>
      </div>
    </div>
  );
}