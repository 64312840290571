import { useDelete } from "../../../hooks/delete";
import Spinner from "../../utils/Spinner";

export default function PersonalImage({data,id, set}) {

  const { handleUpdate,loading } = useDelete("PersonalImages");

  const handleClick = () => {
    handleUpdate(id);
    set(prev => prev.filter((_, i) => i !== id));
}

if (loading) {
  return <Spinner/>;
}
    return (<div className="overflow-hidden h-64 w-64 bg-slate-50 flex flex-col justify-between rounded-md">
        <div className=" w-full h-48  ">
        <img 
          src={data} 
          alt={"personel-image"} 
          className="w-full h-full object-cover"
        />
        </div>
        <button type="button" onClick={handleClick} className=" focus:outline-none text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm mx-2 py-2 my-2  dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Delete</button>
    </div>)
}