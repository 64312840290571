// import gallary1 from "../../../../assets/images/gallary1.svg"
// import gallary2 from "../../../../assets/images/gallary2.svg"
// import gallary3 from "../../../../assets/images/gallary3.svg"
// import gallary4 from "../../../../assets/images/gallary4.svg"

export const gallaryData=[
    "https://res.cloudinary.com/dbrhoa186/image/upload/v1722280411/images/gallary1_ctvpba.svg",
    "https://res.cloudinary.com/dbrhoa186/image/upload/v1722280412/images/gallary2_w75uqd.svg",
    "https://res.cloudinary.com/dbrhoa186/image/upload/v1722280414/images/gallary3_mag6zz.svg",
    "https://res.cloudinary.com/dbrhoa186/image/upload/v1722280418/images/gallary4_dw1c6y.svg",
]