import React from "react";
import Card from "./Card";
import "./Card.css";
import { data } from "./CardData";

const ProjectSection = () => {
  return (
    <div className="sm:block hidden mx-[5.56vw] mt-[13.88vw]">
      <div className="module sticky top-0 flex justify-between">
        <div className="module__content w-1/2">
          <div className=" module__rich-text">
            <h1 className="font-inter text-[3.61vw] font-semibold text-[#FFFFFF]">
              projects
            </h1>
            <p className="font-inter font-medium text-[#FFFFFF80] text-[1.38vw] mt-[1.11vw] leading-[1.94vw]">
              Helping brands achieve sustainable results, <br /> Whether it's a
              vibrant website or a minimal mobile <br /> app or branding you
              seek. I deliver exceptional <br />
              quality for all.
            </p>
          </div>
        </div>
        <div className="module__cards w-1/2  ">
        <Card
            bgColor={"bg-transparent top-[10%] -translate-y-1/2 "}
            rotate={0}
            index={-1}
          />
        {data?.map((e,index)=>(
            <Card
            bgColor={"top-[10%] -translate-y-1/2"}
            rotate={index%2===0?5:-5}
            img={e.img}
            title1={e.title1}
            title2={e.title2}
            title3={e.title3}
            title4={e.title4}
            textColor={e.textColor}
            button={e.button}
            link={e.link}
            btn={e.btnHover}
            index={index}
          />
        ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectSection;
