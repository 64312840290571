// import branding from "../../../../assets/images/branding.svg"
// import code from "../../../../assets/images/code.svg"
// import skill from "../../../../assets/images/skill.svg"
// import logo from "../../../../assets/images/logod.svg"
// import grap from "../../../../assets/images/grap.svg"

export const solutionImages=[
    {
        title:"Web Design",
        img:"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280382/images/branding_saz4qh.svg"
    },
    {
        title:"Mobile App Design",
        img:"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280419/images/skill_xhno5w.svg"
    },
    {
        title:"No Code Development",
        img:"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280385/images/code_onisor.svg"
    },
    {
        title:"Branding",
        img:"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280382/images/branding_saz4qh.svg"
    },
    {
        title:"Graphic Design",
        img:"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280419/images/skill_xhno5w.svg"
    },
    {
        title:"Logo Design",
        img:"https://res.cloudinary.com/dbrhoa186/image/upload/v1722280409/images/logod_bjrlsv.svg"
    },
]