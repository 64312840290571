// import img1 from "../../../../assets/images/comp (1).svg";
// import img2 from "../../../../assets/images/comp (2).svg";
// import img3 from "../../../../assets/images/comp (3).svg";
// import img4 from "../../../../assets/images/comp (4).svg";
// import img5 from "../../../../assets/images/comp (5).svg";
// import img6 from "../../../../assets/images/comp (6).svg";
// import img7 from "../../../../assets/images/comp (7).svg";
// import img8 from "../../../../assets/images/comp (8).svg";
// import img9 from "../../../../assets/images/comp (9).svg";

export const partnerImages = [
  "https://res.cloudinary.com/dbrhoa186/image/upload/v1722280386/images/comp_1_i7mzjv.svg",
  "https://res.cloudinary.com/dbrhoa186/image/upload/v1722280387/images/comp_2_xapxlh.svg",
  "https://res.cloudinary.com/dbrhoa186/image/upload/v1722280388/images/comp_3_i5vpqm.svg",
  "https://res.cloudinary.com/dbrhoa186/image/upload/v1722280388/images/comp_4_g7m3ez.svg",
  "https://res.cloudinary.com/dbrhoa186/image/upload/v1722280389/images/comp_5_ncbcvh.svg",
  "https://res.cloudinary.com/dbrhoa186/image/upload/v1722280390/images/comp_6_h8hw0b.svg",
  "https://res.cloudinary.com/dbrhoa186/image/upload/v1722280392/images/comp_7_fo0q8i.svg",
  'https://res.cloudinary.com/dbrhoa186/image/upload/v1722280391/images/comp_8_mkv5tn.svg',
  "https://res.cloudinary.com/dbrhoa186/image/upload/v1722280392/images/comp_9_lrc64q.svg",
];
